import { gql } from "@apollo/client";

export const MUTATE_CONFIRM_EMAIL = gql`
  mutation ConfirmEmailAddress($userId: ID!, $token: String!) {
    confirmEmailAddress(userId: $userId, token: $token) {
      id
      token
      user {
        id
        email
        name
        bio
        avatarUrl
        artistName
        headerImage
        followsCount
        followedByCount
        projectsCount
        fcm_token
      }
    }
  }
`;

export const RESENDEMAILCONFIRMATIONLINK = gql`
  mutation ResendEmailConfirmationLink($userId: ID!) {
    resendEmailConfirmationLink(userId: $userId)
  }
`;
