import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useLocation, useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CHECK_EMAIL_EXISTS, LOGIN } from "./login_query";

import FormItem from "components/FormItem";
import {
  CheckCircleIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/solid";

import { useForm } from "react-hook-form";
import { XCircleIcon } from "@heroicons/react/20/solid";

import ReactGA4 from "react-ga4";

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  let email: any;
  let password: any;
  const location: any = useLocation();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    /* Non-interaction event */
    ReactGA4.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const [loginmutate, { data, loading, error }] = useMutation(LOGIN);
  const [searchEmail, { data: checkEmailData }] =
    useLazyQuery(CHECK_EMAIL_EXISTS);

  const [enableError, setEnableError] = useState(false);

  const history = useHistory();

  if (data) {
    localStorage.setItem("token", data.login.token);
    localStorage.setItem("name", data.login.user.name);
    localStorage.setItem("user_id", data.login.user.id);
    localStorage.setItem("user_email", data.login.user.email);
    localStorage.setItem("user_avatar", data.login.user.avatarUrl);
    localStorage.setItem("artistName", data.login.user.artistName);
    localStorage.setItem("bio", data.login.user.bio);
    localStorage.setItem("follows_Count", data.login.user.followsCount);
    localStorage.setItem("followedBy_Count", data.login.user.followedByCount);
    localStorage.setItem("projects_Count", data.login.user.projectsCount);
    localStorage.setItem("header_Image", data.login.user.headerImage);

    if (location?.state?.from) {
      history.push(location?.state?.from);
    } else {
      history.push("/");
    }
  }

  useEffect(() => {}, [data]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login - Djaminn</title>
      </Helmet>
      <div className="container font-Montserrat italic">
        <h2 className="my-5 flex items-center font-Montserrat italic text-xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login to your account
        </h2>
        <div className="max-w-xl mx-auto space-y-6">
          <form
            className="grid grid-cols-1 gap-5 mb-48"
            onSubmit={handleSubmit((e: any) => {
              searchEmail({
                variables: {
                  email: email.value,
                },
              });
              if (checkEmailData?.getUserByEmail) {
                loginmutate({
                  variables: {
                    emailUser: email.value,
                    passwordUser: password.value,
                  },
                });
                email.value = "";
                password.value = "";
              }
            })}
          >
            <FormItem label="Email address" desc="">
              <div className="flex">
                <Input
                  {...(register("email"),
                  {
                    onBlur: () => {
                      console.log("from onchange");
                      setEnableError(true);
                      searchEmail({
                        variables: {
                          email: email.value,
                        },
                      });
                    },
                    required: true,
                    ref(instance) {
                      email = instance;
                    },
                    // pattern: "[a-z0-9]+@[a-z]+\\.[a-z]{2,3}",
                  })}
                  type="email"
                  placeholder="example@example.com"
                  className="rounded italic"
                />
              </div>
              {enableError && !checkEmailData?.getUserByEmail ? (
                <span className="inline-flex items-center mt-2 justify-end w-full">
                  <p className="text-xs font-thin text-white mr-2 bg-red-600 p-0.5 rounded-sm">
                    Email doesn't Exist
                  </p>
                  <XCircleIcon className="h-6 w-6 text-red-600" />
                </span>
              ) : (
                <span className="inline-flex items-center mt-2 justify-end w-full">
                  <p className="text-xs text-white font-thin mr-2 bg-green-600 p-0.5 rounded-sm">
                    Email Exist
                  </p>
                  <CheckCircleIcon className="h-6 w-6 text-green-600" />
                </span>
              )}
              {errors.email?.type && (
                <p className="text-red-600">
                  {`*Email* is mandatory ${errors.email?.message}`}
                </p>
              )}
            </FormItem>

            <FormItem label="Password" desc="">
              <div className="flex">
                <Input
                  {...(register("password"),
                  {
                    required: true,
                    ref(instance) {
                      password = instance;
                    },
                  })}
                  type={showPassword ? "text" : "password"}
                  placeholder="type your password"
                  className="rounded italic"
                />
              </div>
            </FormItem>
            {errors.email?.types && (
              <span style={{ color: "red" }}>*Password* is Empty</span>
            )}
            {error ? (
              <div className="flex items-center justify-start space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 text-red-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                  />
                </svg>

                <div className="font-thin text-xs opacity-70">
                  <span className="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-800">
                    Error, while Loging you in!
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="flex flex-row items-center justify-between">
              <button
                className="cursor-pointer"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                <span className="inline-flex items-center mt-0 justify-start w-full">
                  {!showPassword && (
                    <EyeIcon className="h-6 w-6 text-gray-900 dark:text-white" />
                  )}
                  {showPassword && (
                    <EyeSlashIcon className="h-6 w-6 text-gray-900 dark:text-white" />
                  )}
                  <p className="text-xs font-Montserrat italic text-gray-900 dark:text-white mr-2 p-0.5 rounded-sm">
                    {!showPassword && "Show Password"}
                    {showPassword && "Hide Password"}
                  </p>
                </span>
              </button>
              <Link
                to="/forgot-password"
                className="text-sm text-right text-pink-500"
              >
                Forgot password?
              </Link>
            </div>

            <ButtonPrimary
              type="submit"
              disabled={!checkEmailData?.getUserByEmail}
              className="bg-pink-500 shadow-lg font-Montserrat italic hover:bg-pink-400 mt-1"
            >
              {loading ? (
                <>
                  <svg
                    className={`motion-safe:animate-spin dark:text-white text-black  mr-3 h-5 w-5`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <div className="font-thin text-xs opacity-70">
                    <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
                      Loging you in!
                    </span>
                  </div>
                </>
              ) : (
                "Login"
              )}
            </ButtonPrimary>
            <span className="block text-center text-neutral-700 dark:text-neutral-300">
              New user? {` `}
              <Link className="text-pink-500 hover:text-pink-400" to="/signup">
                Create an Account
              </Link>
            </span>
          </form>

          {/* ==== */}
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
