import { gql } from "@apollo/client";

export const SAMPLE_LIST_QUERY = gql`
  query Samples {
    samples {
      id
      name
      mp3Url
      genre {
        id
        name
      }
      bpm
    }
  }
`;
