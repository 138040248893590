import { Helmet } from "react-helmet";
import SectionHappeningNowVideo from "components/SectionHappeningNowVideo";
import SectionHero3 from "components/SectionHero/SectionHero3";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import ReactGA4 from "react-ga4";
import React, { useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_CONTEST_IMAGE,
  GET_HOMESCREEN_WIDGETS,
} from "DataProvider/Queries_gql";
import { Link } from "react-router-dom";

function PageHome3() {
  const [loadingContestDetails, { data: contestData }] =
    useLazyQuery(GET_CONTEST_IMAGE);

  useQuery(GET_HOMESCREEN_WIDGETS, {
    variables: {
      where: {
        status: true,
        type: "contest-start-widget",
      },
      orderBy: "createdAt_DESC",
    },
    onCompleted(data) {
      console.log(data);
      const newcontestIds = data?.getHomescreenConfig?.map(
        (item: any, index: any) => item?.contestId
      );

      loadingContestDetails({
        variables: {
          where: {
            id_in: newcontestIds,
          },
        },
      });
    },
  });

  useEffect(() => {
    /* Non-interaction event */
    ReactGA4.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  console.log("contestData", contestData);
  return (
    <div className="nc-PageHome3 relative overflow-hidden">
      <Helmet>
        <title>Home – CREATE MUSIC WITH OTHERS ALL ON VIDEO</title>
      </Helmet>
      <div className="container px-4">
        {/* SECTION HERO */}
        <BgGlassmorphism />
        <SectionHero3 />
      </div>

      <div className="container space-y-24 lg:space-y-32 lg:mb-32">
        {/* SECTION TABS CONTEST */}

        {contestData?.allContests?.map((item: any, index: any) => {
          return (
            <div className="mt-20" key={`${"" + index}`}>
              <Link to={`/contest/${item?.id}`}>
                <img
                  className="h-auto max-w-full rounded-3xl hover:shadow-[rgba(0,_0,_0,_0.90)_0px_3px_8px] shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] object-contain mx-auto w-full"
                  src={item?.bannerImage}
                  alt="description"
                  title="Go to the contest"
                />
              </Link>
            </div>
          );
        })}
        {/* SECTION */}
        <div className="relative">
          <BgGlassmorphism />
          <SectionHappeningNowVideo />
        </div>
      </div>
    </div>
  );
}

export default PageHome3;
