import React from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
import { NavItemType } from "./NavigationItem";
import { NAVIGATION_DEMO_2 } from "data/navigation";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import HomeNavTab from "shared/HomeNavTab/HomeNavTab";
import ReelsNavTab from "shared/ReelsNavTab/ReelsNavTab";
import ProfileNavTab from "shared/ProfileNavTab/ProfileNavTab";
import AddTrackNavTab from "shared/AddTrackNavTab/AddTrackNavTab";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
  data = NAVIGATION_DEMO_2,
  onClickClose,
}) => {
  return (
    <div className="overflow-y-auto w-full max-w-sm h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      <div className="py-6 px-5">
        <Logo />
        <div className="flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm">
          <span>
            Discover the most outstanding articles on all topics of life. Write
            your stories and share them
          </span>

          <div className="flex justify-between items-center mt-4">
            <span className="flex items-center my-1 mx-1">
              <SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-800 mx-1" />
              <HomeNavTab className="bg-neutral-100 dark:bg-neutral-800 mx-1" />
              <ReelsNavTab className="bg-neutral-100 dark:bg-neutral-800 mx-1" />
              <ProfileNavTab className="bg-neutral-100 dark:bg-neutral-800 mx-1" />
              <AddTrackNavTab className="bg-neutral-100 dark:bg-neutral-800 mx-1" />
            </span>
          </div>
        </div>
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>
      </div>
    </div>
  );
};

export default NavMobile;
