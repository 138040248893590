import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CHECK_EMAIL_EXISTS_SIGNUP, SIGNUP } from "./signup_query";
import FormItem from "components/FormItem";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { useForm } from "react-hook-form";
import ReactGA4 from "react-ga4";

export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const history = useHistory();
  let name: any;
  let artistName: any;
  let email: any;
  let password: any;

  const [signupmutate, { data, loading, error }] = useMutation(SIGNUP);
  useEffect(() => {
    /* Non-interaction event */
    ReactGA4.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  const [searchEmail, { data: checkEmailData }] = useLazyQuery(
    CHECK_EMAIL_EXISTS_SIGNUP
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [enableError, setEnableError] = useState(false);

  if (data?.signUp) {
    localStorage.setItem("token", data.signUp.token);
    localStorage.setItem("name", data.signUp.user.name);
    localStorage.setItem("user_id", data.signUp.user.id);
    localStorage.setItem("user_email", data.signUp.user.email);
    localStorage.setItem("user_avatar", data.signUp.user.avatarUrl);
    localStorage.setItem("artistName", data.signUp.user.artistName);
    localStorage.setItem("bio", data.signUp.user.bio);
    localStorage.setItem("follows_Count", data.signUp.user.followsCount);
    localStorage.setItem("followedBy_Count", data.signUp.user.followedByCount);
    localStorage.setItem("projects_Count", data.signUp.user.projectsCount);
    localStorage.setItem("header_Image", data.signUp.user.headerImage);

    history.push("/");
  }

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up - Djaminn Web Player</title>
      </Helmet>
      <div className="container  font-Montserrat italic">
        <h2 className="my-5 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-Montserrat italic text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          {/* FORM */}
          {error ? (
            <div className="flex items-center justify-start space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 text-red-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                />
              </svg>

              <div className="font-thin text-xs opacity-70">
                <span className="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-800">
                  Error, while Creating Account!
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={handleSubmit((e) => {
              searchEmail({
                variables: {
                  email: email.value,
                },
              });
              if (!checkEmailData?.getUserByEmail) {
                signupmutate({
                  variables: {
                    name: name.value,
                    artistName: artistName.value,
                    email: email.value,
                    password: password.value,
                  },
                });
              } else {
                console.log("That Email Is Already Exits");
              }
            })}
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Name
              </span>
              <Input
                {...(register("name"),
                {
                  required: true,
                  minLength: 3,
                  maxLength: 20,
                  ref(instance) {
                    name = instance;
                  },
                })}
                type="text"
                placeholder="Name"
                className="mt-1 italic"
              />
            </label>

            {errors.name?.type && (
              <p className="text-red-600">
                {`*Email* is mandatory ${errors.name?.message}`}
              </p>
            )}
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                ArtistName
              </span>
              <Input
                {...(register("artistName"),
                {
                  required: true,
                  minLength: 3,
                  maxLength: 20,
                  ref(instance) {
                    artistName = instance;
                  },
                })}
                type="text"
                placeholder="ArtistName"
                className="mt-1 italic"
              />
            </label>
            {errors.artistName?.type && (
              <p className="text-red-600">
                {`*Email* is mandatory ${errors.artistName?.message}`}
              </p>
            )}

            <FormItem label="Email address" desc="">
              <div className="flex">
                <Input
                  {...(register("email"),
                  {
                    onChange: () => {
                      setEnableError(true);
                      searchEmail({
                        variables: {
                          email: email.value,
                        },
                      });
                    },
                    required: true,
                    ref(instance) {
                      email = instance;
                    },
                    // pattern: "[a-z.]+@[a-z]+.[a-z]{2,3}",
                  })}
                  type="email"
                  placeholder="example@example.com"
                  className="rounded italic"
                />
              </div>
              {enableError && checkEmailData?.getUserByEmail && (
                <span className="inline-flex items-center mt-2 justify-end w-full">
                  <p className="text-xs text-white font-thin mr-2 bg-red-600 p-0.5 rounded-sm">
                    That Email is Already Exist, try a different one!
                  </p>
                  <XCircleIcon className="h-6 w-6 text-red-600" />
                </span>
              )}
              {errors.email?.type && (
                <p className="text-red-600">
                  {`*Email* is mandatory ${errors.email?.message}`}
                </p>
              )}
            </FormItem>

            {errors.email?.type && (
              <p className="text-red-600">
                {`*Email* is mandatory ${errors.email?.message}`}
              </p>
            )}
            <FormItem label="Password" desc="">
              <div className="flex">
                <Input
                  {...(register("password"),
                  {
                    required: true,
                    ref(instance) {
                      password = instance;
                    },
                  })}
                  type="password"
                  placeholder="type your password"
                  className="rounded italic"
                />
              </div>
            </FormItem>
            <ButtonPrimary
              type="submit"
              className="bg-pink-500 shadow-lg hover:bg-pink-400 italic"
            >
              {loading ? (
                <>
                  <svg
                    className={`motion-safe:animate-spin dark:text-white text-black  mr-3 h-5 w-5`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <div className="font-thin text-xs opacity-70">
                    <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
                      Please Wait, Creating Account!
                    </span>
                  </div>
                </>
              ) : (
                "Continue"
              )}
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an Account? {` `}
            <Link className="text-pink-500 hover:text-pink-400" to="/login">
              Sign in
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
