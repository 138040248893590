import React, { FC } from "react";
import { nftsLargeImgs } from "contains/fakeData";
import CardNFTVideo from "components/CardNFTVideo";

export interface CardLarge1Props {
  className?: string;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  isShowing?: boolean;
  featuredImgUrl?: string;
  nameP?: string;
  headerImage?: string;
  mixdownVideo?: string;
  ownerAvatar?: string;
  ownerName?: string;
  description?: string;
  commentsCount?: string;
  ClapsCount?: number;
  TracksCount?: string;
  ShareLink?: string;
  ProjectID?: string;
  ProjectTracksArray?: any[];
  ShowAvatarRails?: boolean;
  ownerFCMTOKEN?: string;
}

const CardLarge1: FC<CardLarge1Props> = ({
  className = "",
  isShowing = true,
  onClickNext = () => {},
  onClickPrev = () => {},
  featuredImgUrl = nftsLargeImgs[0],
  nameP,
  headerImage,
  mixdownVideo,
  ownerAvatar,
  ownerName,
  description,
  commentsCount,
  ClapsCount,
  TracksCount,
  ShareLink,
  ProjectID,
  ProjectTracksArray,
  ShowAvatarRails,
  ownerFCMTOKEN,
}) => {
  return (
    <div
      className={`nc-CardLarge1 nc-CardLarge1--hasAnimation relative flex flex-col-reverse lg:flex-row justify-end ${className}`}
    >
      <div className="w-full relative m-0">
        <CardNFTVideo
          featuredImage={headerImage}
          src={mixdownVideo}
          ClapsCount={ClapsCount}
          ProjectID={ProjectID}
          showDetails={false}
          ProjectTracksArray={ProjectTracksArray}
          ShowAvatarRails={ShowAvatarRails}
          ownerFCMTOKEN={ownerFCMTOKEN}
          // isLiked={true}
        />
      </div>
    </div>
  );
};

export default CardLarge1;
