import ButtonDropDownShare from "components/ButtonDropDownShare";
import { FC } from "react";

export interface LikeSaveBtnsProps {
  className?: string;
  ShareLink?: string;
}

const LikeSaveBtns: FC<LikeSaveBtnsProps> = ({ className, ShareLink }) => {
  return (
    <div className="flow-root">
      <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
        <div></div>
        <div className="rounded-sm">
          <ButtonDropDownShare panelMenusClass="!w-52" ShareLink={ShareLink} />
        </div>
      </div>
    </div>
  );
};

export default LikeSaveBtns;
