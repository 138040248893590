import { gql } from "@apollo/client";

export const UPDATE_USER = gql`
  mutation UpdateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUser(data: $data, where: $where) {
      id
      username
      email
      name
      bio
      artistName
      fcm_token
    }
  }
`;

export const GET_REQUESTED_USERDATA_FORSETTNGS = gql`
  query User($id: ID) {
    User(id: $id) {
      id
      avatarUrl
      tags {
        name
        __typename
      }
      artistName
      name
      email
      typeOfMusician
      age
      gender
      bio
      headerImage
      followsCount
      followedByCount
      projectsCount
      notificationsCount
      clapsCount
      commentsCount
      fcm_token
      __typename
    }
  }
`;
