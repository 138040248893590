import { useEffect } from "react";
import { Tab } from "@headlessui/react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_COMMENT_TO_PROJECT } from "DataProvider/Mutations_gql";
import FormItem from "components/FormItem";
import Textarea from "shared/Textarea/Textarea";
import { GET_PROJECT_COMMENTS } from "DataProvider/Queries_gql";
import { Link } from "react-router-dom";

const TabDetail = (props: any) => {
  const TABS = [
    "Comments",
    //  "Users"
  ];

  let comment: any;

  const { ProjectID, ownerFCMTOKEN } = props;

  const {
    data: getAllCommentsData,
    loading: getCommentsLoading,
    error: errorGetComments,
  } = useQuery(GET_PROJECT_COMMENTS, {
    variables: {
      id: ProjectID,
    },
  });

  const [addCommentToProject] = useMutation(ADD_COMMENT_TO_PROJECT, {
    update(cache, { data }) {
      const newCommentFromResponse = data.addCommentToProject;
      const existingComments: any = cache.readQuery({
        query: GET_PROJECT_COMMENTS,
        variables: {
          id: ProjectID,
        },
      });
      if (existingComments && newCommentFromResponse) {
        cache.writeQuery({
          query: GET_PROJECT_COMMENTS,
          variables: {
            id: ProjectID,
          },
          data: {
            allComments: [
              ...(existingComments?.allComments ?? []),
              newCommentFromResponse,
            ],
          },
        });
      }
    },
  });

  useEffect(() => {}, [getAllCommentsData]);

  if (getCommentsLoading) {
    return (
      <div className="container mt-40 mb-40 flex flex-col mx-auto justify-center items-center">
        <svg
          className={`motion-safe:animate-spin dark:text-white text-black -ml-1 mr-3 h-10 w-10`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <div className="font-thin text-xs mt-4">Loading Comments</div>
      </div>
    );
  }

  if (errorGetComments) {
    return (
      <div className="container mt-40 mb-40 flex flex-col mx-auto justify-center items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="text-red-700 -ml-1 mr-3 h-10 w-10"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
          />
        </svg>
        <div className="font-thin text-sm mt-4">
          Error While,Loading Comments
        </div>
      </div>
    );
  }

  const renderTabComments = () => {
    return (
      <>
        <form
          className="grid grid-cols-1 gap-6"
          onSubmit={(e) => {
            e.preventDefault();
            addCommentToProject({
              variables: {
                projectId: ProjectID,
                text: comment.value,
              },
            });
            comment.value = "";
          }}
        >
          <label className="">
            <FormItem
              label=""
              desc={
                <div>
                  Your Comment will be{" "}
                  <span className="text-green-500">Public</span> can be seen by
                  other users.
                </div>
              }
            >
              <Textarea
                ref={(node) => {
                  comment = node;
                }}
                required={true}
                name="comment"
                rows={6}
                className="mt-1.5"
                placeholder="Write your Comment..."
              />
            </FormItem>
          </label>
          <ButtonPrimary
            disabled={!ownerFCMTOKEN}
            type="submit"
            className="bg-pink-500 shadow-lg hover:bg-pink-400 text-black"
          >
            {!ownerFCMTOKEN ? "Unable to comment!" : "Comment"}
          </ButtonPrimary>
        </form>
        <ul className="divide-y divide-neutral-100 dark:divide-neutral-700">
          {getAllCommentsData?.allComments.length > 0 ? (
            getAllCommentsData?.allComments.map(
              (commentdata: any, index: any) => (
                <li
                  key={`${"" + index}`}
                  className={`relative py-4 bg-neutradl-100`}
                >
                  <div className="flex items-center">
                    <Link
                      to={{
                        pathname: `/user/${commentdata.owner.id}`,
                        state: {
                          id: commentdata.owner.id,
                        },
                      }}
                    >
                      <Avatar
                        imgUrl={commentdata.owner.avatarUrl}
                        sizeClass="h-10 w-10"
                        radius="rounded-full"
                      />
                    </Link>
                    <span className="ml-4 text-neutral-500 dark:text-neutral-400 flex flex-col">
                      <span className="flex items-center text-sm">
                        <Link
                          to={{
                            pathname: `/user/${commentdata.owner.id}`,
                            state: {
                              id: props.ownerId,
                            },
                          }}
                        >
                          <span className="font-medium text-neutral-900 dark:text-neutral-200">
                            {commentdata.owner.name}
                          </span>
                        </Link>
                        <span className="ml-2"> • Commented</span>
                      </span>
                      <p className="text-left text-sm "> {commentdata.text} </p>
                      <span className="text-xs mt-1">
                        {commentdata.createdAt.slice(0, 10)}
                      </span>
                    </span>
                  </div>
                </li>
              )
            )
          ) : (
            <div className="container flex flex-row items-center justify-center mt-4 border rounded-xl shadow dark:border-slate-800 border-slate-400 py-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#475569"
                className="w-12 h-12"
              >
                <path
                  fillRule="evenodd"
                  d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
                  clipRule="evenodd"
                />
              </svg>
              <div className="mx-6">
                <p className="text-center">No comments yet</p>
                <p className="text-center text-sm text-slate-600">
                  Be the first to Comment.
                </p>
              </div>
            </div>
          )}
        </ul>
      </>
    );
  };

  const renderTabItem = (item: string) => {
    if (item === "Comments") return renderTabComments();

    return null;
  };

  return (
    <div className="w-full pdx-2 sm:px-0">
      <Tab.Group>
        <Tab.List className="flex justify-start pd-1 space-x-2.5 rounded-full bordedr border-neutral-300 dark:border-neutral-500">
          {TABS.map((tab) => (
            <Tab
              key={tab}
              className={({ selected }) =>
                `px-3.5 sm:px-8 py-1.5 sm:py-2 text-xs sm:text-sm leading-5 font-medium rounded-full focus:outline-none focus:ring-2 ring-primary-300 ${
                  selected
                    ? "bg-neutral-900 dark:bg-neutral-100 text-white dark:text-neutral-900"
                    : "text-neutral-700 dark:text-neutral-300 bg-neutral-100/70 dark:bg-neutral-800 hover:text-neutral-900 dark:hover:text-neutral-100"
                }`
              }
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-4">
          {TABS.map((tab, idx) => (
            <Tab.Panel key={`${"" + idx}`} className={"rounded-xl"}>
              {renderTabItem(tab)}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default TabDetail;
