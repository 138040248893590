import { FC, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useLocation } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useMutation } from "@apollo/client";

import FormItem from "components/FormItem";
import {
  CheckCircleIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/solid";

import { useForm } from "react-hook-form";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { REST_PASSWORD } from "./ResetMutation";

export interface PageLoginProps {
  className?: string;
}

const ResetPasswordPage: FC<PageLoginProps> = ({ className = "" }) => {
  let repassword: any;
  let password: any;
  const [showPassword, setShowPassword] = useState(false);

  const { register, handleSubmit } = useForm();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const accessToken = queryParams.get("access-token");

  const [mutateResetFunction, { data, loading, error }] =
    useMutation(REST_PASSWORD);

  const [passStatus, setPassStatus] = useState<boolean>(false);

  const [touched, setTouched] = useState<boolean>(false);

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Reset Password - Djaminn</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32 font-Montserrat italic">
        <h2 className="my-5 flex font-Montserrat italic items-center text-xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Reset your Password
        </h2>

        <div className="max-w-xl mx-auto space-y-6">
          <form
            className="grid grid-cols-1 gap-5 mb-48"
            onSubmit={handleSubmit((e: any) => {
              if (repassword.value !== password.value) {
                console.log("Not Matched!");
                setPassStatus(false);
              } else {
                console.log("Match!!!!!!");
                setPassStatus(true);
                mutateResetFunction({
                  variables: {
                    tokenByEmail: accessToken,
                    password: password.value,
                  },
                });
              }
            })}
          >
            <FormItem label="Password" desc="" className="font-Montserrat">
              <div className="flex">
                <Input
                  autoFocus={true}
                  type={showPassword ? "text" : "password"}
                  placeholder="type your password"
                  className="rounded italic"
                  {...(register("password"),
                  {
                    required: true,
                    minLength: 8,
                    ref(instance) {
                      password = instance;
                    },
                  })}
                />
              </div>
            </FormItem>
            <FormItem label="Renter Password" desc="">
              <div className="flex">
                <Input
                  type={showPassword ? "text" : "password"}
                  placeholder="type your password"
                  className="rounded italic"
                  {...(register("password"),
                  {
                    required: true,
                    minLength: 8,
                    ref(instance) {
                      repassword = instance;
                    },
                  })}
                  onChange={() => {
                    setTouched(true);
                    if (repassword.value !== password.value) {
                      setPassStatus(false);
                    } else {
                      setPassStatus(true);
                    }
                  }}
                />
              </div>
            </FormItem>
            <div className="flex flex-row items-center justify-between">
              {touched &&
                (!passStatus ? (
                  <div className="">
                    <span className="inline-flex items-center mt-0 justify-start w-full">
                      <XCircleIcon className="h-6 w-6 text-red-600" />
                      <p className="text-xs font-Montserrat italic text-gray-900 dark:text-white mr-2 p-0.5 rounded-sm">
                        Password did not match
                      </p>
                    </span>
                  </div>
                ) : (
                  <div className="">
                    <span className="inline-flex items-center mt-0 justify-start w-full">
                      <CheckCircleIcon className="h-6 w-6 text-green-600" />
                      <p className="text-xs font-Montserrat italic text-gray-900 dark:text-white mr-2  p-0.5 rounded-sm">
                        Password Match
                      </p>
                    </span>
                  </div>
                ))}
              <button
                className="cursor-pointer"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                <span className="inline-flex items-center mt-0 justify-start w-full">
                  {!showPassword && (
                    <EyeIcon className="h-6 w-6 text-gray-900 dark:text-white" />
                  )}
                  {showPassword && (
                    <EyeSlashIcon className="h-6 w-6 text-gray-900 dark:text-white" />
                  )}
                  <p className="text-xs font-Montserrat italic text-gray-900 dark:text-white mr-2 p-0.5 rounded-sm">
                    {!showPassword && "Show Password"}
                    {showPassword && "Hide Password"}
                  </p>
                </span>
              </button>
            </div>
            {error && (
              <div className="flex items-center justify-start space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 text-red-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                  />
                </svg>

                <div className="font-thin text-xs opacity-70">
                  <span className="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-800">
                    Error, while resetting password!
                  </span>
                </div>
              </div>
            )}
            {data && (
              <div className="flex items-center justify-start space-x-2">
                <CheckCircleIcon className="h-6 w-6 text-green-600" />
                <div className="font-thin text-xs opacity-70">
                  <span className="bg-red-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-green-800">
                    Password has been updated!
                  </span>
                </div>
              </div>
            )}

            <ButtonPrimary
              type="submit"
              className="bg-pink-500 shadow-lg hover:bg-pink-400 mt-2 font-Montserrat italic"
            >
              {loading ? (
                <>
                  <svg
                    className={`motion-safe:animate-spin dark:text-white text-black  mr-3 h-5 w-5`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <div className="font-thin text-xs opacity-70">
                    <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
                      Resetting Password
                    </span>
                  </div>
                </>
              ) : (
                "Reset Password"
              )}
            </ButtonPrimary>
            <span className="block text-center text-neutral-700 dark:text-neutral-300">
              New user? {` `}
              <Link className="text-pink-500 hover:text-pink-400" to="/signup">
                Create an Account
              </Link>
            </span>
          </form>

          {/* ==== */}
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
