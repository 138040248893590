import { nanoid } from "@reduxjs/toolkit";
import ButtonPlayMusicRunningContainer from "containers/ButtonPlayMusicRunningContainer";
import { nftsAbstracts } from "contains/fakeData";
import React, { FC, useEffect } from "react";
import NcImage from "shared/NcImage/NcImage";
import AudioForNft from "./AudioForNft";
import musicWave from "images/musicWave.png";

export interface CardNFTMusic2Props {
  className?: string;
  featuredImage?: string;
  src?: string;
  rotatePlayButton?: true | false;
}

const CardNFTMusic2: FC<CardNFTMusic2Props> = ({
  className = "",
  src,
  featuredImage = nftsAbstracts[18],
  rotatePlayButton = false,
}) => {
  const [demoNftId, setDemoNftId] = React.useState(nanoid());

  useEffect(() => {
    setDemoNftId(nanoid());
  }, []);

  const renderAvatars = () => {
    return (
      <div className={`flex-grow flex justify-center`}>
        <img src={musicWave} alt="musicWave" />
      </div>
    );
  };

  const renderIcon = (state?: "loading" | "playing") => {
    switch (state) {
      case "loading":
        return (
          <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z"
            ></path>
            <path
              fill="currentColor"
              d="M9 12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12Z"
            ></path>
            <path
              fill="currentColor"
              d="M17 12C17 12.5523 16.5523 13 16 13C15.4477 13 15 12.5523 15 12C15 11.4477 15.4477 11 16 11C16.5523 11 17 11.4477 17 12Z"
            ></path>
          </svg>
        );

      case "playing":
        return (
          <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24">
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M15.25 6.75V17.25"
            ></path>
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M8.75 6.75V17.25"
            ></path>
          </svg>
        );

      default:
        return (
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M18.25 12L5.75 5.75V18.25L18.25 12Z"
            ></path>
          </svg>
        );
    }
  };

  const renderDefaultBtnListen = (state?: "loading" | "playing") => {
    return (
      <span className="w-10 h-10 sm:w-11 sm:h-11 flex items-center justify-center rounded-full bg-white dark:bg-neutral-900/50 text-primary-6000 dark:text-primary-200 shadow-lg cursor-pointer">
        {renderIcon(state)}
      </span>
    );
  };

  return (
    <div
      className={`nc-CardNFTMusic2 relative flex justify-between p-2 space-x-2 rounded-3xl bg-neutral-100 dark:bg-neutral-800 hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="CardNFTMusic2"
    >
      <div className="flex-grow flex space-x-4">
        <div className="relative w-16 sm:w-24">
          <NcImage
            containerClassName="absolute inset-0 rounded-2xl overflow-hidden shadow-lg"
            src={featuredImage}
          />
        </div>

        <div className="flex flex-col self-center items-center justify-center flex-grow">
          <div className=" flex items-center pt-3 mt-1.5">
            {renderAvatars()}
          </div>
          <div className="flex items-center pt-3 mt-1.5">
            <input
              type="range"
              className="
              form-range
              appearance-none
              w-full
              h-2
              rounded-lg
              p-0
              bg-slate-400
              focus:outline-none focus:ring-0 focus:shadow-2xl"
              id="customRange1"
            />
          </div>
        </div>
      </div>

      <ButtonPlayMusicRunningContainer
        nftId={demoNftId}
        className={`flex items-center ${rotatePlayButton ? "" : "rotate-90"}`}
        renderDefaultBtn={() => renderDefaultBtnListen()}
        renderLoadingBtn={() => renderDefaultBtnListen("loading")}
        renderPlayingBtn={() => renderDefaultBtnListen("playing")}
      ></ButtonPlayMusicRunningContainer>

      {/* AUDIO MEDiA */}
      <AudioForNft src={src} className="absolute opacity-0" nftId={demoNftId} />
    </div>
  );
};

export default CardNFTMusic2;
