import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation ($emailUser: String!, $passwordUser: String!) {
    login(email: $emailUser, password: $passwordUser) {
      id
      token
      user {
        id
        email
        name
        bio
        avatarUrl
        artistName
        headerImage
        followsCount
        followedByCount
        projectsCount
        fcm_token
      }
    }
  }
`;

export const CHECK_EMAIL_EXISTS = gql`
  query Query($email: String!) {
    getUserByEmail(email: $email)
  }
`;
