import React, { FC } from "react";
import imagePng from "images/hero3.jpg";
import AppStorePng from "../../images/socials/appstore.png";
import PlayStorePng from "../../images/socials/playstore.png";

export interface SectionHero3Props {
  className?: string;
}

const SectionHero3: FC<SectionHero3Props> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero3 relative mb-24 ${className}`}
      data-nc-id="SectionHero3"
    >
      <div className="mt-10 lg:mt-0 lg:absolute lg:container z-10 inset-x-0 top-[10%] sm:top-[20%] sm:left-[3%]">
        <div className="flex flex-col items-start max-w-2xl space-y-5 xl:space-y-8 ">
          <span className="sm:text-lg md:text-xl xl:text-2xl font-Montserrat italic text-lime-300">
            CO-CREATION FOR MUSICIANS.
          </span>
          <div className="">
            <h2 className="font-Montserrat italic tracking-tighter text-pink-500 text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl !leading-[90%] ">
              GET
            </h2>
            <h2 className="font-Montserrat italic tracking-tighter text-pink-500 text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl !leading-[90%] ">
              YOURSELF
            </h2>
            <h2 className="font-Montserrat italic tracking-tighter text-pink-500 text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl !leading-[90%] ">
              IN THE MIX.
            </h2>
          </div>
          <h3 className="font-Montserrat italic tracking-tighter text-lime-300 text-sm sm:text-4xl md:text-5xl lg:text-4xl xl:text-5xl !leading-[80%] ">
            START A NEW TRACK.
          </h3>
        </div>
        {/* <HeroSearchForm className="mt-5 lg:mt-24 2xl:mt-40" /> */}
        <div className="flex flex-row w-80 justify-start items-center">
          <div className="w-min h-16 flex items-center mt-3">
            <a
              className="h-auto w-36"
              href="https://apps.apple.com/tt/app/djaminn-create-songs-together/id1634589883"
            >
              <img
                src={AppStorePng}
                alt="App Store"
                className="h-auto w-36 object-contain "
              />
            </a>
          </div>
          <div className="w-min h-16 flex items-center mt-3 ml-3">
            <a
              className="h-auto w-36"
              href="https://play.google.com/store/apps/details?id=com.djamin"
            >
              <img
                src={PlayStorePng}
                alt="Play Sotre"
                className="h-auto w-36 object-contain "
              />
            </a>
          </div>
        </div>
      </div>
      <div className="relative aspect-w-4 aspect-h-3 sm:aspect-w-16 sm:aspect-h-7">
        <img
          className="absolute inset-0 object-cover rounded-[32px]"
          src={imagePng}
          alt="hero"
        />
      </div>
    </div>
  );
};

export default SectionHero3;
