import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightLargeImgDark from "images/newforlast.svg";
import Logo from "shared/Logo/Logo";

export interface SectionBecomeAnAuthorProps {
  className?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
}) => {
  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
      data-nc-id="SectionBecomeAnAuthor"
    >
      <div className="flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
        <Logo className="w-28" />
        <h2 className="font-semibold text-3xl sm:text-4xl xl:text-6xl mt-6 sm:mt-10 !leading-[1.112] tracking-tight">
          Your Work <br /> Is Protected
        </h2>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400 ">
          All added tracks are copyright protected, and you will always be owner
          of your own work.
        </span>
      </div>
      <div className="flex-grow">
        <NcImage containerClassName="" src={rightLargeImgDark} />
      </div>
    </div>
  );
};

export default SectionBecomeAnAuthor;
