import React, { FC, useEffect } from "react";
import Avatar from "shared/Avatar/Avatar";
import NcImage from "shared/NcImage/NcImage";
import LikeButton from "./LikeButton";
import ButtonPlayMusicRunningContainer from "containers/ButtonPlayMusicRunningContainer";
import { nanoid } from "@reduxjs/toolkit";
import VideoForNft from "./VideoForNft";

export interface CardNFTVideoProps {
  className?: string;
  featuredImage?: string;
  isLiked?: boolean;
  src?: string;
  reelName?: string;
  UserAvatar?: string;
  ownerName?: string;
  ClapsCount?: number;
  ProjectID?: string;
  showDetails?: boolean;
  ProjectTracksArray?: any[];
  ShowAvatarRails?: boolean;
  ownerFCMTOKEN?: string;
  showIndexNumber?: boolean;
  IndexNumber?: number;
}

const CardNFTVideo: FC<CardNFTVideoProps> = ({
  src,
  featuredImage,
  className = "",
  isLiked,
  reelName,
  UserAvatar,
  ownerName,
  ClapsCount,
  ProjectID,
  showDetails,
  ProjectTracksArray,
  ShowAvatarRails = true,
  ownerFCMTOKEN,
  showIndexNumber = false,
  IndexNumber,
}) => {
  const [demoNftId, setDemoNftId] = React.useState(nanoid());

  const renderAvatars = () => {
    return (
      <div className="hidden sm:flex -space-x-1 ">
        <Avatar
          containerClassName="ring-2 ring-white dark:ring-neutral-900"
          sizeClass="h-7 w-7 text-sm"
          imgUrl={UserAvatar}
        />
      </div>
    );
  };

  const renderIcon = (state?: "playing" | "loading") => {
    if (!state) {
      return (
        <svg className="ml-0.5 w-9 h-9" fill="currentColor" viewBox="0 0 24 24">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M18.25 12L5.75 5.75V18.25L18.25 12Z"
          ></path>
        </svg>
      );
    }

    return (
      <svg className=" w-9 h-9" fill="none" viewBox="0 0 24 24">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M15.25 6.75V17.25"
        ></path>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8.75 6.75V17.25"
        ></path>
      </svg>
    );
  };

  const renderListenButtonDefault = (state?: "playing" | "loading") => {
    return (
      <div
        className={`w-14 h-14 flex items-center justify-center rounded-full  cursor-pointer ${
          state === "playing"
            ? "bg-neutral-900/40 text-primary-50"
            : "bg-neutral-50/80 text-primary-500"
        }`}
      >
        {renderIcon(state)}
      </div>
    );
  };

  useEffect(() => {
    setDemoNftId(nanoid());
  }, []);
  return (
    <div
      className={`nc-CardNFTVideo relative flex flex-col group ${className}`}
      data-nc-id="CardNFTVideo"
    >
      <VideoForNft src={src} nftId={demoNftId} />
      <div className="relative flex-shrink-0 ">
        <div className="">
          <NcImage
            src={featuredImage}
            containerClassName="flex aspect-w-8 aspect-h-9 w-full h-0 rounded-3xl overflow-hidden z-0"
            className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
          />
        </div>

        {localStorage.getItem("token") ? (
          <LikeButton
            disableLike={!ownerFCMTOKEN}
            ClapsCount={ClapsCount}
            ProjectID={ProjectID}
            className="absolute top-3 right-3 z-10 !h-9"
          />
        ) : (
          ""
        )}
        {showIndexNumber ? (
          <div className="absolute left-3 top-3 z-10 !h-9 flex items-center justify-center">
            <span className="font-Montserrat bg-lime-400 text-black dark:backdrop-blur-3xl text-2xl font-extrabold mr-2 px-3.5 py-0.5 rounded dark:bg-lime-400 dark:text-black transform -skew-x-12">
              {" "}
              {IndexNumber}
            </span>
          </div>
        ) : (
          <></>
        )}

        <ButtonPlayMusicRunningContainer
          className="absolute bottom-3 left-3 z-10"
          nftId={demoNftId}
          renderDefaultBtn={() => renderListenButtonDefault()}
          renderPlayingBtn={() => renderListenButtonDefault("playing")}
          renderLoadingBtn={() => renderListenButtonDefault("loading")}
        />
        {ShowAvatarRails ? (
          <>
            {ProjectTracksArray !== null ? (
              <div className="absolute bottom-3 z-10 right-6">
                <div className="flex flex-row">
                  {ProjectTracksArray?.map((item: any, index: any) => {
                    return (
                      <div
                        className="flex items-center py-4 relative w-7"
                        key={`${"" + index}`}
                      >
                        <Avatar
                          imgUrl={item.originalOwner.avatarUrl}
                          sizeClass="h-8 w-8"
                          radius="rounded-full"
                          containerClassName="border-0 shadow-lg"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <></>
        )}
      </div>

      {!showDetails ? (
        <> </>
      ) : (
        <div className="p-5">
          <div className="flex justify-between items-center">
            <h2 className={`sm:text-lg font-Montserrat italic`}>{reelName}</h2>
            <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-2">
              <div className="flex items-center space-x-3">
                {renderAvatars()}
              </div>
              <div className="flex items-center">
                <div className="">
                  <div className="text-sm font-semibold flex items-center">
                    <span>{ownerName}</span>
                  </div>
                  <div className="text-xs dark:text-neutral-400">Creator</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="p-5">
        <div className="flex justify-between items-end">
          <div className="text-right"></div>
        </div>
      </div>
    </div>
  );
};

export default CardNFTVideo;
