import { gql } from "@apollo/client";

export const SIGNUP = gql`
  mutation (
    $name: String!
    $artistName: String!
    $email: String!
    $password: String!
  ) {
    signUp(
      name: $name
      artistName: $artistName
      password: $password
      email: $email
    ) {
      id
      token
      user {
        id
        email
        name
        bio
        avatarUrl
        artistName
        headerImage
        followsCount
        followedByCount
        projectsCount
        fcm_token
      }
    }
  }
`;

export const CHECK_EMAIL_EXISTS_SIGNUP = gql`
  query Query($email: String!) {
    getUserByEmail(email: $email)
  }
`;
