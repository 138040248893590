import { gql } from "@apollo/client";

export const ADD_COMMENT_TO_PROJECT = gql`
  mutation AddCommentToProject($projectId: ID!, $text: String) {
    addCommentToProject(projectId: $projectId, text: $text) {
      id
      createdAt
      owner {
        id
        name
        avatarUrl
        fcm_token
      }
      project {
        id
        name
      }
      text
    }
  }
`;

export const LIKE_PROJECT_MUTATION = gql`
  mutation ClapProject($projectId: ID!) {
    clapProject(projectId: $projectId) {
      id
      owner {
        fcm_token
      }
    }
  }
`;

export const UNLIKE_PROJECT_MUTATION = gql`
  mutation unLikeTrack($projectId: ID!) {
    unclapProject(projectId: $projectId) {
      count
      __typename
    }
  }
`;

export const FOLLOW_USER_NOW = gql`
  mutation followUser($unfollowUserId: ID!) {
    followUser(id: $unfollowUserId) {
      id
      fcm_token
      __typename
    }
  }
`;

export const UNFOLLOW_USER_NOW = gql`
  mutation UnfollowUser($unfollowUserId: ID!) {
    unfollowUser(id: $unfollowUserId) {
      id
      __typename
    }
  }
`;

export const CURRENT_USER_CLAPED_PROJECT = gql`
  query User($userId: ID) {
    User(id: $userId) {
      clappedProjects {
        id
        fcm_token
        name
      }
    }
  }
`;

export const GET_PROJECT_CLAP = gql`
  query ProjectClap($projectClapId: ID) {
    ProjectClap(id: $projectClapId) {
      id
    }
  }
`;
