import React, { useEffect } from "react";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import CardNFTMusic2 from "components/CardNFTMusic2";
import Heading from "components/Heading/Heading";
import { Helmet } from "react-helmet";
import AddTrackBtn from "components/AddTrackBtn/AddTrackBtn";
import WavesurferMusic from "components/WaveFormSurfer/WavesurferMusic";
import { Link } from "react-router-dom";

export interface AddDjaminnPageProps {
  className?: string;
}
const AddDjaminnPage: React.FC<AddDjaminnPageProps> = ({ className = "" }) => {
  useEffect(() => {}, []);

  return (
    <div className="  text-center items-center justify-around align-middle text-7xl my-20">
      <Helmet>
        <title>Add Djaminn - Djaminn</title>
      </Helmet>
      <Heading
        className=" dark:text-neutral-50 text-black-900"
        fontClass="text-3xl md:text-4xl 2xl:text-5xl font-bold"
        isCenter
        desc="Your Djaminn Name 🎵"
      >
        MIXDOWN
      </Heading>

      {/*  */}
      <div className="container w-1/6 border-b-2 border-neutral-100 dark:border-neutral-700 mt-10"></div>
      <BgGlassmorphism />

      <div className="h-auto flex flex-row items-center justify-center space-y-24 mb-10">
        <div className="items-center justify-center flex flex-col space-y-10 -rotate-90">
          <div className="h-full flex flex-row items-center">
            <Link to={"/sample-list"}>
              <AddTrackBtn className="mr-3" />
            </Link>
            <CardNFTMusic2 className="shadow-xl hover:shadow-lg bg-gradient-to-r from-[#fef9c3] via-[#a3e635] to-[#ec4899]" />
          </div>
          <div className="h-full flex flex-row items-center">
            <Link to={"/sample-list"}>
              <AddTrackBtn className="mr-3" />
            </Link>
            <CardNFTMusic2 className="shadow-xl hover:shadow-lg bg-gradient-to-r from-[#fef9c3] via-[#a3e635] to-[#ec4899]" />
          </div>
          <div className="h-full flex flex-row items-center">
            <Link to={"/sample-list"}>
              <AddTrackBtn className="mr-3" />
            </Link>

            <CardNFTMusic2 className="shadow-xl hover:shadow-lg bg-gradient-to-r from-[#fef9c3] via-[#a3e635] to-[#ec4899]" />
          </div>
          <div className="h-full flex flex-row items-center">
            <Link to={"/sample-list"}>
              <AddTrackBtn className="mr-3" />
            </Link>

            <CardNFTMusic2 className="shadow-xl hover:shadow-lg bg-gradient-to-r from-[#fef9c3] via-[#a3e635] to-[#ec4899]" />
          </div>
          <div className="h-full flex flex-row items-center">
            <Link to={"/sample-list"}>
              <AddTrackBtn className="mr-3" />
            </Link>
            <CardNFTMusic2 className="shadow-xl hover:shadow-lg bg-gradient-to-r from-[#fef9c3] via-[#a3e635] to-[#ec4899]" />
          </div>
        </div>
      </div>

      <div className="container  w-1/6 border-b-2 border-neutral-100 dark:border-neutral-700"></div>
      <WavesurferMusic url="https://www.mfiles.co.uk/mp3-downloads/franz-schubert-standchen-serenade.mp3" />
    </div>
  );
};

export default AddDjaminnPage;
