import CardLarge1 from "components/CardLarge1/CardLarge1";
import { nftsLargeImgs } from "contains/fakeData";
import React, { FC, useState } from "react";

export interface SectionLargeSliderProps {
  className?: string;
  nameP?: string;
  headerImage?: string;
  mixdownVideo?: string;
  ownerAvatar?: string;
  ownerName?: string;
  description?: string;
  commentsCount?: string;
  ClapsCount?: number;
  TracksCount?: string;
  ShareLink?: string;
  ProjectID?: string;
  ProjectTracksArray?: any;
  ShowAvatarRails?: boolean;
  ownerFCMTOKEN?: string;
}

const SectionLargeSlider: FC<SectionLargeSliderProps> = ({
  className = "",
  nameP,
  headerImage,
  mixdownVideo,
  ownerAvatar,
  ownerName,
  description,
  commentsCount,
  ClapsCount,
  TracksCount,
  ShareLink,
  ProjectID,
  ProjectTracksArray,
  ShowAvatarRails,
  ownerFCMTOKEN,
}) => {
  const [indexActive, setIndexActive] = useState(0);

  const handleClickNext = () => {
    setIndexActive((state) => {
      if (state >= 2) {
        return 0;
      }
      return state + 1;
    });
  };

  const handleClickPrev = () => {
    setIndexActive((state) => {
      if (state === 0) {
        return 2;
      }
      return state - 1;
    });
  };

  return (
    <div className={`nc-SectionLargeSlider relative ${className}`}>
      {[1, 1, 1].map((_, index) =>
        indexActive === index ? (
          <CardLarge1
            key={`${"" + index}`}
            isShowing
            featuredImgUrl={nftsLargeImgs[index]}
            onClickNext={handleClickNext}
            onClickPrev={handleClickPrev}
            nameP={nameP}
            headerImage={headerImage}
            mixdownVideo={mixdownVideo}
            ownerAvatar={ownerAvatar}
            ownerName={ownerName}
            description={description}
            commentsCount={commentsCount}
            ClapsCount={ClapsCount}
            TracksCount={TracksCount}
            ShareLink={ShareLink}
            ProjectID={ProjectID}
            ProjectTracksArray={ProjectTracksArray}
            ShowAvatarRails={ShowAvatarRails}
            ownerFCMTOKEN={ownerFCMTOKEN}
          />
        ) : null
      )}
    </div>
  );
};

export default SectionLargeSlider;
