import React from "react";

const PlaceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="148.983"
      height="129.694"
      viewBox="0 0 148.983 129.694"
      className="w-full h-full"
    >
      <g
        id="Group_2"
        data-name="Group 2"
        transform="translate(-516.114 -153.585)"
      >
        <g
          id="Group_1"
          data-name="Group 1"
          transform="translate(516.114 153.585)"
        >
          <g
            id="undraw_happy_music_g6wc_2_"
            data-name="undraw_happy_music_g6wc (2)"
            transform="translate(0 0)"
          >
            <path
              id="Path_1"
              data-name="Path 1"
              d="M148.983,734.016,42.334,735.624,0,728.121H66.089Z"
              transform="translate(0 -605.93)"
              fill="#e6e6e6"
            />
            <circle
              id="Ellipse_1"
              data-name="Ellipse 1"
              cx="17.06"
              cy="17.06"
              r="17.06"
              transform="translate(105.752 0)"
              fill="#ff6584"
            />
            <path
              id="Path_2"
              data-name="Path 2"
              d="M335.954,773.86s.25-5.229,5.365-4.621"
              transform="translate(-305.774 -650.778)"
              fill="#3f3d56"
            />
            <circle
              id="Ellipse_2"
              data-name="Ellipse 2"
              cx="2.56"
              cy="2.56"
              r="2.56"
              transform="translate(26.174 112.986)"
              fill="#ec4899"
            />
            <rect
              id="Rectangle_1"
              data-name="Rectangle 1"
              width="0.723"
              height="5.059"
              transform="translate(28.318 119.858)"
              fill="#3f3d56"
            />
            <path
              id="Path_3"
              data-name="Path 3"
              d="M838.4,779.182s.25-5.229,5.365-4.621"
              transform="translate(-723.902 -655.207)"
              fill="#3f3d56"
            />
            <circle
              id="Ellipse_3"
              data-name="Ellipse 3"
              cx="2.56"
              cy="2.56"
              r="2.56"
              transform="translate(110.493 113.879)"
              fill="#ec4899"
            />
            <rect
              id="Rectangle_2"
              data-name="Rectangle 2"
              width="0.723"
              height="5.059"
              transform="translate(112.637 120.751)"
              fill="#3f3d56"
            />
            <path
              id="Path_4"
              data-name="Path 4"
              d="M911.851,784.505s.25-5.229,5.365-4.621"
              transform="translate(-785.026 -659.636)"
              fill="#3f3d56"
            />
            <circle
              id="Ellipse_4"
              data-name="Ellipse 4"
              cx="2.56"
              cy="2.56"
              r="2.56"
              transform="translate(122.819 114.772)"
              fill="#ec4899"
            />
            <rect
              id="Rectangle_3"
              data-name="Rectangle 3"
              width="0.723"
              height="5.059"
              transform="translate(124.963 121.644)"
              fill="#3f3d56"
            />
            <path
              id="Path_5"
              data-name="Path 5"
              d="M456.243,758.956s.25-5.229,5.365-4.621"
              transform="translate(-405.876 -638.375)"
              fill="#3f3d56"
            />
            <circle
              id="Ellipse_5"
              data-name="Ellipse 5"
              cx="2.56"
              cy="2.56"
              r="2.56"
              transform="translate(46.361 110.485)"
              fill="#ec4899"
            />
            <rect
              id="Rectangle_4"
              data-name="Rectangle 4"
              width="0.723"
              height="5.059"
              transform="translate(48.505 117.357)"
              fill="#3f3d56"
            />
            <path
              id="Path_6"
              data-name="Path 6"
              d="M229.5,764.279s.25-5.229,5.365-4.621"
              transform="translate(-217.188 -642.805)"
              fill="#3f3d56"
            />
            <circle
              id="Ellipse_6"
              data-name="Ellipse 6"
              cx="2.56"
              cy="2.56"
              r="2.56"
              transform="translate(8.31 111.378)"
              fill="#ec4899"
            />
            <rect
              id="Rectangle_5"
              data-name="Rectangle 5"
              width="0.723"
              height="5.059"
              transform="translate(10.454 118.25)"
              fill="#3f3d56"
            />
            <circle
              id="Ellipse_7"
              data-name="Ellipse 7"
              cx="27.896"
              cy="27.896"
              r="27.896"
              transform="translate(46.326 56.597)"
              fill="#3f3d56"
            />
            <path
              id="Path_7"
              data-name="Path 7"
              d="M481.147,510.295a7.382,7.382,0,0,1,11.331,0,8.2,8.2,0,1,0-11.331,0Z"
              transform="translate(-424.487 -423.563)"
              fill="#fff"
            />
            <path
              id="Path_8"
              data-name="Path 8"
              d="M613.152,510.295a7.382,7.382,0,0,1,11.331,0,8.2,8.2,0,1,0-11.331,0Z"
              transform="translate(-534.34 -423.563)"
              fill="#fff"
            />
            <circle
              id="Ellipse_8"
              data-name="Ellipse 8"
              cx="2.822"
              cy="2.822"
              r="2.822"
              transform="translate(56.703 75.181)"
              fill="#3f3d56"
            />
            <circle
              id="Ellipse_9"
              data-name="Ellipse 9"
              cx="2.822"
              cy="2.822"
              r="2.822"
              transform="translate(78.855 75.181)"
              fill="#3f3d56"
            />
            <circle
              id="Ellipse_10"
              data-name="Ellipse 10"
              cx="3.282"
              cy="3.282"
              r="3.282"
              transform="translate(50.838 88.185)"
              fill="#ff6584"
            />
            <circle
              id="Ellipse_11"
              data-name="Ellipse 11"
              cx="3.282"
              cy="3.282"
              r="3.282"
              transform="translate(88.58 88.185)"
              fill="#ff6584"
            />
            <path
              id="Path_9"
              data-name="Path 9"
              d="M422.737,505.926l-2.461,10.666,4.1-4.1Z"
              transform="translate(-349.747 -421.023)"
              fill="#ff6584"
            />
            <path
              id="Path_10"
              data-name="Path 10"
              d="M465.582,668.946l-2.794-2.345.065,2.345h-.868l-.069-2.48-3.773,2.48h-1.579l5.324-3.5-.206-7.349-.154-5.55.866-.024.156,5.574.206,7.342,4.176,3.506Z"
              transform="translate(-379.944 -543.02)"
              fill="#3f3d56"
            />
            <path
              id="Path_11"
              data-name="Path 11"
              d="M387.362,668.946l-2.794-2.345.065,2.345h-.866l-.069-2.48-3.773,2.48h-1.579l5.322-3.5-.206-7.349-.154-5.55.868-.024.156,5.574.2,7.342,4.177,3.506Z"
              transform="translate(-314.852 -543.02)"
              fill="#3f3d56"
            />
            <path
              id="Path_12"
              data-name="Path 12"
              d="M556.081,372.342a2.3,2.3,0,0,0-1.892,1.508c-.4-1.373-1.234-2.328-2.21-2.328a1.483,1.483,0,0,0-.2.033c-.375-1.466-1.247-2.494-2.264-2.494-1.359,0-2.461,1.837-2.461,4.1s1.1,4.1,2.461,4.1a1.484,1.484,0,0,0,.2-.033c.375,1.466,1.247,2.494,2.264,2.494a2.3,2.3,0,0,0,1.892-1.508c.4,1.373,1.234,2.328,2.21,2.328,1.359,0,2.461-1.837,2.461-4.1S557.44,372.342,556.081,372.342Z"
              transform="translate(-481.449 -317.796)"
              fill="#3f3d56"
            />
            <path
              id="Path_13"
              data-name="Path 13"
              d="M0,0H0A8.2,8.2,0,0,1,8.2,8.2v4.1a8.2,8.2,0,0,1-8.2,8.2H0Z"
              transform="translate(48.037 95.064) rotate(180)"
              fill="#ec4899"
            />
            <path
              id="Path_14"
              data-name="Path 14"
              d="M598.3,444.248h0a8.2,8.2,0,0,1,8.2,8.2v4.1a8.2,8.2,0,0,1-8.2,8.2h0V444.248Z"
              transform="translate(-497.898 -369.696)"
              fill="#ec4899"
            />
            <path
              id="Path_15"
              data-name="Path 15"
              d="M470.18,405.637h-1.3a29.941,29.941,0,1,0-59.882,0h-1.3a31.243,31.243,0,1,1,62.485,0Z"
              transform="translate(-365.475 -322.235)"
              fill="#ec4899"
            />
            <path
              id="Path_16"
              data-name="Path 16"
              d="M131.69,368Z"
              transform="translate(-109.59 -306.241)"
              fill="#3f3d56"
            />
            <path
              id="Path_17"
              data-name="Path 17"
              d="M300.733,402.444h0l-.612-.255-2.58-1.091-.925-.391-.071.179h0l-1.242,3.137,3.5,1.48.018.017-.236.556a.988.988,0,0,0-.082.16.771.771,0,0,0,.441.948c.295.117.667-.126.833-.543a.877.877,0,0,0-.284-1.06h0l-.612-.255-2.092-.813,1.2-3.018-.177-.07-1.2,3.019-.259-.1,1.2-3.031.032.013,2.459,1.041.018.017-.236.556a.982.982,0,0,0-.082.16.771.771,0,0,0,.441.948c.295.117.667-.126.832-.543A.877.877,0,0,0,300.733,402.444Z"
              transform="translate(-271.945 -344.133)"
              fill="#3f3d56"
            />
            <path
              id="Path_18"
              data-name="Path 18"
              d="M368.766,435.052a2.358,2.358,0,0,1-1.015-2.318c-.032-1.035-.887-.888-.887-.888l-.311.071a.944.944,0,0,0-.463-.086l-.473.08a1.123,1.123,0,0,1,.989,1.155l.151,1.054-2.824-1.139.324-.764a1.362,1.362,0,0,0,.114-.223,1.071,1.071,0,0,0-.613-1.317c-.409-.162-.927.176-1.156.755A1.218,1.218,0,0,0,363,432.9h0l.849.354.006-.02Zm-1.434-1.852.091,1.009a2.327,2.327,0,0,1-.424-1.545.977.977,0,0,0-.189-.541,1.146,1.146,0,0,1,.523,1.077Z"
              transform="translate(-327.875 -369.038)"
              fill="#3f3d56"
            />
            <path
              id="Path_19"
              data-name="Path 19"
              d="M242.591,350.712h0l-.849-.354-3.55-1.38.9-.8a1.132,1.132,0,0,1,1.547-.253l-.278-.413s-.523-.692-1.254.04a2.358,2.358,0,0,1-2.327.994l4.857,2.056.025.023-.327.772a1.365,1.365,0,0,0-.114.223,1.071,1.071,0,0,0,.613,1.317c.409.162.927-.176,1.156-.755A1.218,1.218,0,0,0,242.591,350.712Z"
              transform="translate(-223.238 -299.62)"
              fill="#3f3d56"
            />
            <path
              id="Path_20"
              data-name="Path 20"
              d="M324.67,307.977l-.327.772a1.364,1.364,0,0,0-.114.223,1.071,1.071,0,0,0,.613,1.317c.409.162.927-.176,1.156-.755a1.218,1.218,0,0,0-.394-1.472h0l-.849-.354-2.906-1.13,1.66-4.192-.246-.1-1.661,4.194-.359-.14,1.667-4.21.044.019,3.416,1.446.026.023-.327.772a1.365,1.365,0,0,0-.114.223,1.071,1.071,0,0,0,.613,1.317c.409.162.927-.176,1.156-.755a1.218,1.218,0,0,0-.394-1.472h0l-.849-.354-3.471-1.467h0l-.113-.048-1.285-.543-1.824,4.606,4.857,2.056ZM321.513,301.541Z"
              transform="translate(-292.32 -261.401)"
              fill="#3f3d56"
            />
            <path
              id="Path_21"
              data-name="Path 21"
              d="M207.517,170.727h0l-1.462-.609-6.111-2.376,1.542-1.372a1.948,1.948,0,0,1,2.663-.436l-.479-.711s-.9-1.191-2.159.069c0,0-1.558,2.3-4.007,1.712l8.36,3.539.044.04-.563,1.328a2.35,2.35,0,0,0-.2.383,1.844,1.844,0,0,0,1.055,2.267c.7.279,1.6-.3,1.991-1.3A2.1,2.1,0,0,0,207.517,170.727Z"
              transform="translate(-190.558 -147.741)"
              fill="#3f3d56"
            />
            <path
              id="Path_22"
              data-name="Path 22"
              d="M209.754,267.108c-3.035-1.735-2.428-5.543-2.428-5.543-.076-2.474-2.12-2.124-2.12-2.124l-.744.17a2.259,2.259,0,0,0-1.106-.2l-1.132.19a2.686,2.686,0,0,1,2.365,2.761l.361,2.52-6.751-2.723.775-1.828a3.263,3.263,0,0,0,.273-.532,2.561,2.561,0,0,0-1.466-3.148c-.979-.388-2.216.42-2.765,1.8-.541,1.365-.012,3.1.941,3.518v0l2.031.846.015-.048Zm-3.428-4.428.217,2.421a5.546,5.546,0,0,1-1.014-3.7,2.337,2.337,0,0,0-.452-1.294,2.741,2.741,0,0,1,1.249,2.576Z"
              transform="translate(-188.308 -224.166)"
              fill="#3f3d56"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PlaceIcon;
