import Label from "components/Label/Label";
import { FC, useEffect } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_REQUESTED_USERDATA_FORSETTNGS,
  UPDATE_USER,
} from "./accountupdate_query";

import ReactGA4 from "react-ga4";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  let username: any;
  let email: any;
  let bio: any;
  let artistName: any;

  const {
    data: GRUData,
    loading: GRUDataLoading,
    error: GRUDataError,
  } = useQuery(GET_REQUESTED_USERDATA_FORSETTNGS, {
    variables: {
      id: localStorage.getItem("user_id"),
    },
  });

  const [updateusermutation, { loading, error }] = useMutation(UPDATE_USER, {
    refetchQueries: () => [
      {
        query: GET_REQUESTED_USERDATA_FORSETTNGS,
        variables: {
          id: localStorage.getItem("user_id"),
        },
      },
    ],
  });

  useEffect(() => {
    /* Non-interaction event */

    ReactGA4.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  if (GRUDataLoading) {
    return (
      <div className="container mt-40 mb-40 flex flex-col mx-auto justify-center items-center">
        <svg
          className={`motion-safe:animate-spin dark:text-white text-black -ml-1 mr-3 h-10 w-10`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <div className="font-thin text-xs mt-4">
          Fetching Current Credentials
        </div>
      </div>
    );
  }

  if (GRUDataError) {
    return (
      <div className="container mt-40 mb-40 flex flex-col mx-auto justify-center items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="text-red-700 -ml-1 mr-3 h-10 w-10"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
          />
        </svg>
        <div className="font-thin text-sm mt-4">Error Fetching Credentials</div>
      </div>
    );
  }

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account Settings - Djaminn</title>
      </Helmet>
      <div className="container flex flex-col mx-auto ">
        <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
          {/* HEADING */}
          <div className="max-w-2xl">
            <h2 className="text-3xl sm:text-4xl font-Montserrat italic">
              Profile settings
            </h2>
            <span className="block font-Montserrat italic mt-3 text-neutral-500 dark:text-neutral-400">
              You can set preferred display name, create your profile and manage
              other personal settings.
            </span>
          </div>
          <div className="w-full border-b border-neutral-100 dark:border-neutral-700"></div>
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={(e) => {
              e.preventDefault();
              updateusermutation({
                variables: {
                  data: {
                    name: username.value,
                    email: email.value,
                    bio: bio.value,
                    artistName: artistName.value,
                  },
                  where: {
                    id: GRUData?.User.id,
                  },
                },
              });
            }}
          >
            <div className="flex flex-col md:flex-row">
              <div className="flex-grow mt-10 md:mt-0 max-w-3xl space-y-5 sm:space-y-6 md:sm:space-y-7">
                {/* ---- */}
                <div className="mt-2 mb-10 flex flex-col justify-center items-center px-2 pt-2 pb-2 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-xl">
                  <img
                    src={GRUData?.User.headerImage}
                    className="w-full h-52 object-cover opacity-20 rounded-xl overflow-hidden"
                    alt="headerImage"
                  />

                  <div className="mx-auto flex flex-col items-center justify-center absolute top-96">
                    <div className="space-y-1 text-center flex items-center justify-center rounded-md backdrop-blur-sm mt-2 sm:mt-5">
                      <svg
                        className="mx-auto h-12 w-12 text-neutral-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <div className="flex text-sm text-neutral-6000 dark:text-neutral-300 m-2 items-center justify-center">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-500"
                        >
                          <span className="text-pink-500 hover:text-pink-400">
                            Upload Image
                          </span>
                          <p className="text-xs text-neutral-500 dark:text-neutral-400">
                            PNG, JPG, GIF up to 10MB
                          </p>
                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            className="sr-only"
                          />
                        </label>
                      </div>
                    </div>

                    <div className="flex-shrink-0 flex items-start mt-10">
                      <div className="relative rounded-full overflow-hidden flex">
                        <Avatar
                          sizeClass="w-32 h-32"
                          imgUrl={GRUData?.User.avatarUrl}
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                              stroke="currentColor"
                              strokeWidth={1.5}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>

                          <span className="mt-1 text-xs text-center text-pink-500 font-medium">
                            Change Profile Image
                          </span>
                        </div>
                        <input
                          type="file"
                          className="absolute inset-0 opacity-0 cursor-pointer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* ---- */}
                <div>
                  <Label>Username</Label>
                  <Input
                    ref={(node) => {
                      username = node;
                    }}
                    className="mt-1.5"
                    defaultValue={GRUData?.User.name}
                    value={username}
                  />
                </div>

                {/* ---- */}
                <div>
                  <Label>Email</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl las la-envelope"></i>
                    </span>
                    <Input
                      ref={(node) => {
                        email = node;
                      }}
                      disabled={true}
                      className="!rounded-l-none cursor-no-drop"
                      placeholder="example@email.com"
                      defaultValue={GRUData?.User.email}
                      value={email}
                    />
                  </div>
                </div>

                {/* ---- */}
                <div>
                  <Label>Bio</Label>
                  <Textarea
                    ref={(node) => {
                      bio = node;
                    }}
                    rows={5}
                    className="mt-1.5"
                    placeholder="Something about yourself in a few word."
                    defaultValue={GRUData?.User.bio}
                    value={bio}
                  />
                </div>

                {/*  */}
                <div>
                  <Label>Artist Name</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl las la-compact-disc"></i>
                    </span>
                    <Input
                      ref={(node) => {
                        artistName = node;
                      }}
                      className="!rounded-l-none"
                      placeholder="Artist Name"
                      sizeClass="h-11 px-4 pl-2 pr-3"
                      defaultValue={GRUData?.User.artistName}
                      value={artistName}
                    />
                  </div>
                </div>
                {error ? (
                  <div className="flex items-center justify-start space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 text-red-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                      />
                    </svg>

                    <div className="font-thin text-xs opacity-70">
                      <span className="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-800">
                        Error, while updating settings!
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* ---- */}
                <div className="pt-2">
                  <ButtonPrimary className="w-full bg-pink-500 hover:bg-pink-400">
                    {loading ? (
                      <>
                        <svg
                          className={`motion-safe:animate-spin dark:text-white text-black  mr-3 h-5 w-5`}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        <div className="font-thin text-xs opacity-70">
                          <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
                            Updating Profile...
                          </span>
                        </div>
                      </>
                    ) : (
                      "Update Profile"
                    )}
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
