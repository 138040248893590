import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";

const linGrad: any = document
  .createElement("canvas")
  .getContext("2d")
  ?.createLinearGradient(0, 0, 500, 128);

linGrad?.addColorStop(1, "#a3e635");
linGrad?.addColorStop(0, "#ec4899");

const formWaveSurferOptions = (ref: any) => ({
  container: ref,
  waveColor: "#555",
  progressColor: linGrad,
  cursorColor: "#a3e635",
  barWidth: 4,
  barRadius: 3,
  responsive: true,
  autoCenter: true,
  hideScrollbar: true,
  height: 70,
  barGap: 2,
  // If true, normalize by the maximum peak instead of 1.0.
  normalize: true,
  // Use the PeakCache to improve rendering speed of large waveforms.
  partialRender: true,
  devicePixelRatio: 2,
});

type WavesurferMusicProps = {
  className?: string;
  url?: string;
  disablePlayButton?: true | false;
  autoPlayOncheck?: true | false;
};

const WavesurferMusic: React.FC<WavesurferMusicProps> = ({
  className,
  url,
  disablePlayButton = false,
  autoPlayOncheck = false,
}) => {
  const waveformRef = useRef<any>(null);
  const wavesurfer = useRef<any>(null);
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);

  useEffect(() => {
    setPlaying(false);
    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    wavesurfer.current.load(url);

    wavesurfer.current.on("ready", function () {
      // https://wavesurfer-js.org/docs/methods.html
      if (autoPlayOncheck === true) {
        wavesurfer.current.play();
        setPlaying(true);
      }

      // make sure object stillavailable when file loaded
      if (wavesurfer.current && wavesurfer.current !== undefined) {
        wavesurfer.current.setVolume(volume);
        setVolume(volume);
      }
    });

    // Removes events, elements and disconnects Web Audio nodes.
    // when component unmount
    return () => wavesurfer.current.destroy();
  }, [url, autoPlayOncheck, volume]);

  const handlePlayPause = () => {
    setPlaying(!playing);
    wavesurfer.current.playPause();
  };

  return (
    <div
      className={`container flex flex-col w-[50%] self-center mt-5  ${className}`}
    >
      <div id="waveform" ref={waveformRef} />
      {!disablePlayButton && (
        <button
          onClick={handlePlayPause}
          className="rounded-full flex flex-col items-center justify-center cursor-pointer"
        >
          {!playing ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-10 h-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-10 h-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 5.25v13.5m-7.5-13.5v13.5"
              />
            </svg>
          )}

          <p className="text-sm text-center font-light">
            {!playing ? "Play" : "Pause"}
          </p>
        </button>
      )}
    </div>
  );
};

export default WavesurferMusic;
