export default {
  imagePreSetLib: "preset-lib-staging",
  // Live Server
  ip: "https://djaminn.app/", // (PROD)
  // ip: "https://djaminn.trailfive.com/", // (DEV)

  // Dev Server
  // ip: "https://djaminn.trailfive.com/",

  // ip: "https://api.djmm.in/",
  sampleLib: "sample-lib-staging",
};
