import configFile from "configFile";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

type Props = {};

const UnsubscribePage = (props: Props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const source = queryParams.get("source");
  const [isloading, setIsloading] = useState<boolean>(false);
  const [status, setstatus] = useState<any>();
  console.log("source", source);
  const urlunsub =
    source === "dev" ? "https://djaminn.trailfive.com" : "https://djaminn.app";
  const unsubNow = async () => {
    setIsloading(true);
    try {
      setIsloading(true);
      const response = await fetch(`${urlunsub}/unsubscribe`, {
        method: "POST", // Specify the method as POST
        headers: {
          "Content-Type": "application/json", // Set the content type to JSON
        },
        body: JSON.stringify({
          id: id,
        }), // Convert the data to a JSON string
      });

      if (response.status === 200) {
        setstatus(200);
      }
    } catch (error) {
      setIsloading(false);
      console.error("Error:", error); // Log any errors
    } finally {
      setIsloading(false);
    }
  };

  return (
    <section className="bg-white dark:bg-gray-900">
      {status !== 200 && (
        <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
          <figure className="max-w-screen-md mx-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21.75 9v.906a2.25 2.25 0 0 1-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 0 0 1.183 1.981l6.478 3.488m8.839 2.51-4.66-2.51m0 0-1.023-.55a2.25 2.25 0 0 0-2.134 0l-1.022.55m0 0-4.661 2.51m16.5 1.615a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V8.844a2.25 2.25 0 0 1 1.183-1.981l7.5-4.039a2.25 2.25 0 0 1 2.134 0l7.5 4.039a2.25 2.25 0 0 1 1.183 1.98V19.5Z"
              />
            </svg>

            <div className="mx-auto max-w-screen-sm">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                Unsubscribing newsletter
              </h2>
              <p className="mb-4 font-light text-gray-500 lg:mb-4 sm:text-md dark:text-gray-400">
                Do you really want to unsubscribe, you will no longer receive
                valuable promotions and tips from Djaminn via email. Are you
                sure you want to miss out?
              </p>
            </div>
            <div className="flex items-center gap-5 justify-center">
              <Link
                to={"/"}
                type="button"
                className="rounded-md uppercase disabled:bg-gray-500 bg-green-900/60 px-4 py-2 text-sm font-medium text-white hover:shadow-xl hover:bg-green-700/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
              >
                Cancel
              </Link>
              <button
                disabled={isloading}
                type="button"
                className="rounded-md disabled:bg-gray-500 flex uppercase bg-red-700/90 px-4 py-2 text-sm font-medium text-white hover:shadow-xl hover:bg-red-500/90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
                onClick={async () => {
                  unsubNow();
                }}
              >
                {isloading && (
                  <svg
                    className={`motion-safe:animate-spin dark:text-white text-black  mr-3 h-5 w-5`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                unsubscribe now!
              </button>
            </div>
          </figure>
        </div>
      )}

      {/* unsub message */}

      {status === 200 && (
        <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
          <figure className="max-w-screen-md mx-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              fill="currentColor"
              className="h-12 mx-auto mb-3 text-green-600"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                clipRule="evenodd"
              />
            </svg>

            <div className="mx-auto max-w-screen-sm">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                Successfully unsubscribed from the newsletter.
              </h2>
              <p className="mb-4 font-light text-gray-500 lg:mb-4 sm:text-md dark:text-gray-400">
                You're unsubscribed. You will no longer receive promotions and
                tips from Djaminn.
              </p>
            </div>
            <div className="flex items-center gap-5 justify-center">
              <Link
                to={"/"}
                type="button"
                className="rounded-md uppercase bg-green-900/60 px-4 py-2 text-sm font-medium text-white hover:shadow-xl hover:bg-green-700/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
              >
                Go, back to home
              </Link>
            </div>
          </figure>
        </div>
      )}
    </section>
  );
};

export default UnsubscribePage;
