import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import NcImage from "shared/NcImage/NcImage";
import FollowButton from "components/FollowButton";
import { AM_I_FOLLOWING_USER } from "DataProvider/Queries_gql";
import { useLazyQuery } from "@apollo/client";

export interface CardAuthorBox3Props {
  className?: string;
  avatarUrl?: string;
  UserId?: string;
  FCM_Token?: string;
  name?: string;
  bio?: string;
  headerImage?: string;
  __typename?: string;
}

const CardAuthorBox3: FC<CardAuthorBox3Props> = ({
  className = "",
  avatarUrl,
  FCM_Token,
  UserId,
  name,
  bio,
  headerImage,
  __typename,
}) => {
  const token = localStorage.getItem("token");

  const [
    CheckYouAreFollowing,
    {
      data: CheckYouAreFollowingData,
      loading: CheckYouAreFollowingLoading,
      error: CheckYouAreFollowingError,
    },
  ] = useLazyQuery(AM_I_FOLLOWING_USER, {
    variables: {
      amIFollowingUserId: UserId,
    },
  });

  useEffect(() => {
    if (token) {
      CheckYouAreFollowing();
    }
    return () => {};
  }, [CheckYouAreFollowing, token]);

  if (CheckYouAreFollowingLoading) {
    return (
      <div className="container mt-40 flex flex-col mx-auto justify-center items-center">
        <svg
          className={`motion-safe:animate-spin text-white -ml-1 mr-3 h-10 w-10`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <div className="font-thin text-xs mt-4">Fetching</div>
      </div>
    );
  }

  if (CheckYouAreFollowingError) {
    return (
      <div className="container mt-40 flex flex-col mx-auto justify-center items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="animate-ping text-red-700 -ml-1 mr-3 h-10 w-10"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
          />
        </svg>
        <div className="font-thin text-sm mt-4">Error Fetching</div>
      </div>
    );
  }

  return (
    <div
      className={`nc-CardAuthorBox3 relative flex flex-col p-4 overflow-hidden [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="CardAuthorBox3"
    >
      <div className="relative flex-shrink-0 flex space-x-2 h-24">
        <NcImage
          containerClassName="flex flex-grow h-full rounded-xl overflow-hidden"
          src={headerImage}
        />
      </div>

      <div className="-mt-6">
        <div className="text-center">
          <Avatar
            containerClassName="ring-4 ring-white dark:ring-black !shadow-xl"
            sizeClass="w-12 h-12 text-2xl"
            radius="rounded-full"
            imgUrl={avatarUrl}
          />
        </div>
        <div className="mt-2.5 flex items-start justify-between">
          <div>
            <h2 className={`text-base font-medium flex items-center`}>
              <span className="">{name}</span>
              {/* <VerifyIcon /> */}
            </h2>
          </div>

          {token && (
            <FollowButton
              disableButton={!FCM_Token}
              isFollowing={CheckYouAreFollowingData?.amIFollowingUser.value}
              UserId={UserId}
            />
          )}
        </div>
        <div className="mt-4 text-sm text-neutral-500 dark:text-neutral-400">
          {bio}
        </div>
      </div>

      <Link
        to={{
          pathname: `/user/${UserId}`,
          state: {
            id: UserId,
          },
        }}
        className="absolute inset-0"
      ></Link>
    </div>
  );
};

export default CardAuthorBox3;
