import { useEffect } from "react";
import MyRouter from "routers/index";
import ReactGA4 from "react-ga4";

function App() {
  useEffect(() => {
    ReactGA4.initialize(
      [
        {
          trackingId: "G-VJBKPX10VF",
        },
        {
          trackingId: "G-JLFK8VKZ1H",
        },
        {
          trackingId: "G-G90D0HEVV6",
        },
      ],
      { testMode: true }
    );
  }, []);
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 min-h-screen flex flex-col justify-between font-sans antialiased">
      <MyRouter />
    </div>
  );
}

export default App;
