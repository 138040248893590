import { FC, Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import NftMoreDropdown from "components/NftMoreDropdown";
import ButtonDropDownShare from "components/ButtonDropDownShare";
import { Tab } from "@headlessui/react";
import CardAuthorBox3 from "components/CardAuthorBox3/CardAuthorBox3";
import CardNFTVideo from "components/CardNFTVideo";
import { IdentificationIcon } from "@heroicons/react/24/solid";
import { useQuery } from "@apollo/client";
import {
  GET_LOGGED_USERDATA,
  GET_USERS_FOLLOWERS,
  GET_USERS_FOLLOWINGS,
  GET_USERS_TRACKS,
} from "./auther_Page_query";
import { Link } from "react-router-dom";
import ReactGA4 from "react-ga4";

export interface AuthorPageProps {
  className?: string;
}

const AuthorPage: FC<AuthorPageProps> = ({ className = "" }) => {
  const me = localStorage.getItem("user_id");

  const {
    data: GLUData,
    loading: GRUDataLoading,
    error: GRUDataError,
  } = useQuery(GET_LOGGED_USERDATA, {
    variables: {
      id: `${localStorage.getItem("user_id")}`,
    },
  });

  const { data, loading, error } = useQuery(GET_USERS_TRACKS, {
    variables: {
      filter: {
        status: "PUBLISHED",
        owner: {
          id: `${localStorage.getItem("user_id")}`,
        },
      },
    },
  });
  const {
    data: userFollowingData,
    loading: userFollowingLoading,
    error: userFollowingError,
    refetch: refechFollowings,
  } = useQuery(GET_USERS_FOLLOWINGS, {
    variables: {
      me: `${localStorage.getItem("user_id")}`,
      name: "",
      take: 20,
    },
  });

  const {
    data: userFollowersData,
    loading: userFollowersLoading,
    error: userFollowersError,
    refetch: refechFollowers,
  } = useQuery(GET_USERS_FOLLOWERS, {
    variables: {
      me: `${localStorage.getItem("user_id")}`,
      name: "",
      take: 20,
    },
  });

  const [categories, setCategories] = useState([
    `Tracks`,
    `Following`,
    `Followers`,
  ]);

  const GA_Event = (name: string) => {
    ReactGA4.event({
      category: "User Personal Tracks",
      label: `${name}`,
      action: `Djaminn - ${name}`,
      value: 1,
    });
  };

  useEffect(() => {
    setCategories([`Tracks`, `Following`, `Followers`]);
  }, []);

  useEffect(() => {
    /* Non-interaction event */
    ReactGA4.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  if (
    loading ||
    userFollowingLoading ||
    userFollowersLoading ||
    GRUDataLoading
  ) {
    return (
      <div className="container mt-40 mb-40 flex flex-col mx-auto justify-center items-center">
        <svg
          className={`motion-safe:animate-spin dark:text-white text-black -ml-1 mr-3 h-10 w-10`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <div className="font-thin text-xs mt-4">Fetching Profile Data</div>
      </div>
    );
  }

  if (error || userFollowingError || userFollowersError || GRUDataError) {
    return (
      <div className="container mt-40 mb-40 flex flex-col mx-auto justify-center items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="dark:text-red-700 text-red-700 -ml-1 mr-3 h-10 w-10"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
          />
        </svg>
        <div className="font-thin text-sm mt-4">
          Error Fetching profile Data
        </div>
      </div>
    );
  }

  return (
    <div className={`nc-AuthorPage  ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>Profile - Djaminn</title>
      </Helmet>

      {/* HEADER */}
      <div className="w-full">
        <div className="relative w-full h-40 md:h-60 2xl:h-72">
          <NcImage
            containerClassName="absolute inset-0"
            src={GLUData?.User.headerImage}
            className="object-cover w-full h-full"
          />
        </div>
        <div className="container -mt-10 lg:-mt-16">
          <div className="relative bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row">
            <div className="w-32 lg:w-44 flex-shrink-0 mt-12 sm:mt-0">
              <NcImage
                src={GLUData?.User.avatarUrl}
                containerClassName="aspect-w-1 aspect-h-1 rounded-3xl overflow-hidden"
              />
            </div>
            <div className="pt-5 md:pt-1 md:ml-6 xl:ml-14 flex-grow">
              <div className="max-w-screen-sm ">
                <h2 className="inline-flex items-center text-2xl sm:text-3xl lg:text-4xl font-semibold">
                  <span>{GLUData?.User.artistName}</span>
                </h2>

                <div className="flex items-center text-sm font-medium space-x-2.5 mt-2.5 text-sky-300 cursor-pointer">
                  <IdentificationIcon className="h-5 w-5" />
                  <span className="block text-sm font-thin text-neutral-500 dark:text-slate-500">
                    {GLUData?.User.bio}
                  </span>
                </div>
              </div>
            </div>
            <div className="absolute md:static left-5 top-4 sm:left-auto sm:top-5 sm:right-5 flex flex-row-reverse justify-end">
              <NftMoreDropdown
                actions={[
                  {
                    id: "report",
                    name: "Report abuse",
                    icon: "las la-flag",
                  },
                ]}
                containerClassName="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
              />
              <ButtonDropDownShare
                className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer mx-2"
                panelMenusClass="origin-top-right !-right-5 !w-40 sm:!w-52"
                ShareLink={`${window.location.origin}/user/${me}`}
              />
            </div>
          </div>
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <main>
          <Tab.Group>
            <div className="flex flex-col lg:flex-row justify-between ">
              <Tab.List className="flex space-x-0 sm:space-x-2 overflow-x-auto ">
                {categories.map((item) => (
                  <Tab key={item} as={Fragment}>
                    {({ selected }) => (
                      <button
                        onClick={() => {
                          refechFollowers();
                          refechFollowings();
                        }}
                        className={`flex-shrink-0 block font-medium px-4 py-2 text-sm sm:px-6 sm:py-2.5 capitalize rounded-full focus:outline-none font-Montserrat italic ${
                          selected
                            ? "bg-neutral-900 dark:bg-neutral-100 text-neutral-50 dark:text-neutral-900"
                            : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100/70 dark:hover:bg-neutral-800"
                        } `}
                      >
                        {item}
                      </button>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </div>
            <Tab.Panels>
              <Tab.Panel className="">
                {/* LOOP ITEMS */}
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
                  {data?.allProjects.map((item: any, index: any) => (
                    <Link
                      to={{
                        pathname: `/UserTracksDetailsPage/${index}/${localStorage.getItem(
                          "user_id"
                        )}`,
                        state: {
                          indexVideo: index,
                          USER_ID: localStorage.getItem("user_id"),
                        },
                      }}
                      onClick={() => {
                        GA_Event(item.name);
                      }}
                      key={`${"" + index}`}
                    >
                      <CardNFTVideo
                        key={`${"" + index}`}
                        featuredImage={item.headerImage}
                        reelName={item.name}
                        src={item.mixdownVideo}
                        UserAvatar={item.owner.avatarUrl}
                        ownerName={item.owner.name}
                        ProjectID={item.id}
                        showDetails={false}
                        ownerFCMTOKEN={item.owner.fcm_token}
                      />
                    </Link>
                  ))}
                </div>

                {/* PAGINATION */}
              </Tab.Panel>
              <Tab.Panel className="">
                {/* LOOP ITEMS */}
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mt-8 lg:mt-10">
                  {userFollowingData?.allUsers.map((item: any, index: any) => (
                    <CardAuthorBox3
                      key={`${"" + index}`}
                      UserId={item.id}
                      __typename={item.__typename}
                      avatarUrl={item.avatarUrl}
                      FCM_Token={item.fcm_token}
                      bio={item.bio}
                      headerImage={item.headerImage}
                      name={item.name}
                    />
                  ))}
                </div>

                {/* PAGINATION */}
              </Tab.Panel>
              <Tab.Panel className="">
                {/* LOOP ITEMS */}
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
                  {userFollowersData?.allUsers.map((item: any, index: any) => (
                    <CardAuthorBox3
                      key={`${"" + index}`}
                      UserId={item.id}
                      __typename={item.__typename}
                      avatarUrl={item.avatarUrl}
                      FCM_Token={item.fcm_token}
                      bio={item.bio}
                      headerImage={item.headerImage}
                      name={item.name}
                    />
                  ))}
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </main>
      </div>
    </div>
  );
};

export default AuthorPage;
