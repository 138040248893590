import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export interface AddTrackNavTabProps {
  className?: string;
}
const AddTrackNavTab: React.FC<AddTrackNavTabProps> = ({ className = "" }) => {
  useEffect(() => {}, []);

  return (
    <Link
      to={"/add-your-djaminn"}
      className={`text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center ${className}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    </Link>
  );
};

export default AddTrackNavTab;
