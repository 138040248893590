import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import icon from "../../images/logo.png";
import { useMutation } from "@apollo/client";
import {
  MUTATE_CONFIRM_EMAIL,
  RESENDEMAILCONFIRMATIONLINK,
} from "./confirmEmail_mutation";

import { EnvelopeIcon } from "@heroicons/react/24/solid";

type Props = {};

const ConfirmEmailPage = (props: Props) => {
  const [remainingSeconds, setRemainingSeconds] = useState(10);
  const [mutate, { data, loading, error }] = useMutation(MUTATE_CONFIRM_EMAIL);
  const [
    resendEmailConfirmationLink,
    { data: resendLinkData, loading: resendLinkLoading },
  ] = useMutation(RESENDEMAILCONFIRMATIONLINK);

  const history = useHistory();
  const params: any = useParams();
  const location = useLocation<any>();
  const queryParams = new URLSearchParams(location.search);

  const userId = params.passID;
  const confirmationToken = queryParams.get("token");

  // for redirection to homepage

  useEffect(() => {
    if (data?.confirmEmailAddress?.user) {
      const timeoutId = setInterval(() => {
        setRemainingSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      if (remainingSeconds === 0) {
        history.replace("/");
        clearInterval(timeoutId);
      }
      return () => {
        clearInterval(timeoutId);
      };
    }
  }, [data?.confirmEmailAddress?.user, history, remainingSeconds]);

  useEffect(() => {
    if (userId && confirmationToken) {
      mutate({
        variables: {
          userId: userId,
          token: confirmationToken,
        },
      });
    }
  }, [userId, confirmationToken, mutate]);

  if (loading) {
    return (
      <div className="container mt-40 mb-40 flex flex-col mx-auto justify-center items-center">
        <svg
          className={`motion-safe:animate-spin dark:text-white text-black -ml-1 mr-3 h-10 w-10`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <div className="font-thin text-xs mt-4">Confirming email</div>
      </div>
    );
  }

  return (
    <section className="bg-white italic text-base font-Montserrat dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <div className="max-w-screen-xl px-4 py-8 mx-auto flex flex-row w-full lg:py-16 lg:px-6">
        <figure className="max-w-screen-md mx-auto text-left">
          <figcaption className=" flex items-center justify-start my-6  space-x-3">
            <img
              className="h-8 w-auto object-contain"
              src={icon}
              alt="profile "
            />
          </figcaption>
          {data?.confirmEmailAddress && (
            <>
              <h1 className="mb-4 text-left text-4xl font-Montserrat font-black tracking-tight leading-none text-gray-900 lg:mb-6 md:text-5xl xl:text-6xl dark:text-white">
                Congratulations! 🎉
              </h1>
              <p className="mb-4 text-left text-xl font-normal tracking-tight leading-relaxed text-gray-900 lg:mb-6 dark:text-white">
                <span className="font-medium text-xl">
                  Hi! {data?.confirmEmailAddress?.user?.name},
                </span>{" "}
                <br />
                Your email has been successfully confirmed!.
              </p>
              <blockquote>
                <p className="text-lg text-left font-light text-gray-500">
                  You will be redirect shortly to home page in{" "}
                  {remainingSeconds} Seconds
                </p>
              </blockquote>
            </>
          )}

          {error?.message && (
            <>
              <p className="mb-4 text-left text-xl font-normal tracking-tight leading-relaxed text-gray-900 lg:mb-6 dark:text-white">
                {error?.message}!
              </p>
              {error?.message === "The link is invalid" && (
                <p className="text-lg text-left font-light text-gray-500 my-3">
                  The link you click to confirm your email address is invalid.
                  <br />
                  Click below to Resend confirmation link.
                </p>
              )}
              {error?.message === "The link has been expired" && (
                <p className="text-lg text-left font-light text-gray-500 my-3">
                  The link you click to confirm your email address no longer
                  available.
                  <br />
                  Click below to Resend confirmation link.
                </p>
              )}
              <blockquote>
                <button
                  type="button"
                  className="text-gray-50 bg-pink-600 hover:bg-pink-600/90 focus:ring-0 focus:outline-none focus:ring-[#050708]/50 italic rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"
                  onClick={() => {
                    resendEmailConfirmationLink({
                      variables: {
                        userId: userId,
                      },
                    });
                  }}
                >
                  {!resendLinkLoading ? (
                    <EnvelopeIcon className="mr-3 h-5 w-5" />
                  ) : (
                    <svg
                      className={`motion-safe:animate-spin dark:text-white text-black  mr-3 h-5 w-5`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  Resend Confirmation Link
                </button>
                {resendLinkData && (
                  <p className="text-gray-500 opacity-50">
                    {resendLinkData?.resendEmailConfirmationLink}
                  </p>
                )}
              </blockquote>
            </>
          )}
        </figure>
      </div>
    </section>
  );
};

export default ConfirmEmailPage;
