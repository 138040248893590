import { useMutation } from "@apollo/client";
import {
  GET_USERS_FOLLOWERS,
  GET_USERS_FOLLOWINGS,
} from "containers/AuthorPage/auther_Page_query";
import { FOLLOW_USER_NOW, UNFOLLOW_USER_NOW } from "DataProvider/Mutations_gql";
import { AM_I_FOLLOWING_USER } from "DataProvider/Queries_gql";
import React, { FC, useEffect } from "react";
import ButtonPrimary, { ButtonPrimaryProps } from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface FollowButtonProps extends ButtonPrimaryProps {
  isFollowing?: boolean;
  UserId?: string;
  disableButton?: boolean;
}

const FollowButton: FC<FollowButtonProps> = ({
  className = "relative z-10",
  sizeClass = "px-4 py-1.5 min-w-[84px]",
  fontSize = "text-sm font-medium",
  isFollowing,
  UserId,
  disableButton,
}) => {
  const [
    unFollowUser,
    { loading: loadingUnFollowUser, error: errorUnFollowUser },
  ] = useMutation(UNFOLLOW_USER_NOW);

  const [FollowUser, { loading: loadingFollowUser, error: errorFollowUser }] =
    useMutation(FOLLOW_USER_NOW);

  const unFollowUserHandler = () => {
    console.log("unFollow");
    unFollowUser({
      variables: {
        unfollowUserId: UserId,
      },
      refetchQueries: () => [
        {
          query: GET_USERS_FOLLOWINGS,
          variables: {
            me: `${localStorage.getItem("user_id")}`,
            name: "",
            take: 20,
          },
        },
        {
          query: GET_USERS_FOLLOWERS,
          variables: {
            me: `${localStorage.getItem("user_id")}`,
            name: "",
            take: 20,
          },
        },
        {
          query: AM_I_FOLLOWING_USER,
          variables: {
            amIFollowingUserId: UserId,
          },
        },
      ],
    });
  };

  const FollowUserHandler = () => {
    console.log("Follow");
    FollowUser({
      variables: {
        unfollowUserId: UserId,
      },
      refetchQueries: () => [
        {
          query: GET_USERS_FOLLOWINGS,
          variables: {
            me: `${localStorage.getItem("user_id")}`,
            name: "",
            take: 20,
          },
        },
        {
          query: GET_USERS_FOLLOWERS,
          variables: {
            me: `${localStorage.getItem("user_id")}`,
            name: "",
            take: 20,
          },
        },
        {
          query: AM_I_FOLLOWING_USER,
          variables: {
            amIFollowingUserId: UserId,
          },
        },
      ],
    });
  };

  useEffect(() => {}, [isFollowing]);

  if (loadingFollowUser || loadingUnFollowUser) {
    return (
      <div className=" flex flex-row mx-auto justify-center items-center">
        {/* <div className="font-thin text-xs">Loading</div> */}
        <svg
          className={`animate-spin dark:text-white text-black h-7 w-7`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  }

  if (errorFollowUser || errorUnFollowUser) {
    return (
      <div className=" flex flex-row justify-center items-center">
        <div className="font-thin text-xs">Error</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="dark:text-red-700 text-red-700 h-7 w-7"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            className="text-red-600"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
          />
        </svg>
      </div>
    );
  }

  return !isFollowing ? (
    <ButtonPrimary
      disabled={disableButton}
      title={disableButton ? "Unable to follow" : ""}
      className={className}
      sizeClass={sizeClass}
      fontSize={fontSize}
      onClick={FollowUserHandler}
    >
      Follow
    </ButtonPrimary>
  ) : (
    <ButtonSecondary
      disabled={disableButton}
      title={disableButton ? "Unable to unfollow" : ""}
      className={className}
      sizeClass={sizeClass}
      fontSize={fontSize}
      onClick={unFollowUserHandler}
    >
      <span className="text-sm ">Following</span>
    </ButtonSecondary>
  );
};

export default FollowButton;
