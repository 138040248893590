import { gql } from "@apollo/client";

export const GET_REQUESTED_USERDATA = gql`
  query UserPlayer($id: ID) {
    UserPlayer(id: $id) {
      avatarUrl
      tags {
        name
        __typename
      }
      artistName
      name
      email
      typeOfMusician
      age
      gender
      bio
      headerImage
      followsCount
      followedByCount
      projectsCount
      notificationsCount
      clapsCount
      commentsCount
      fcm_token
      __typename
    }
  }
`;

export const GET_SINGLE_USERPAGE_TRACKS = gql`
  query userTracksPlayer($filter: ProjectWhereInput, $skip: Int, $take: Int) {
    allProjectsPlayer(
      filter: $filter
      orderBy: createdAt_DESC
      skip: $skip
      take: $take
    ) {
      id
      name
      description
      headerImage
      videoExist
      mixdownVideo
      mixdownAudio
      mixdownPath
      clapsCount
      owner {
        id
        name
        artistName
        avatarUrl
        fcm_token
      }
    }
  }
`;

export const GET_SINGLE_USERPAGE_FOLLOWINGS = gql`
  query AllUsersPlayer($filter: UserWhereInput, $take: Int) {
    allUsersPlayer(filter: $filter, take: $take) {
      avatarUrl
      bio
      headerImage
      name
      artistName
      id
      fcm_token
      __typename
    }
  }
`;

export const GET_SINGLE_USERPAGE_FOLLOWERS = gql`
  query AllUsersPlayer($filter: UserWhereInput, $take: Int) {
    allUsersPlayer(filter: $filter, take: $take) {
      avatarUrl
      bio
      headerImage
      name
      artistName
      id
      fcm_token
      __typename
    }
  }
`;
