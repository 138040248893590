import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useLazyQuery, useMutation } from "@apollo/client";
import FormItem from "components/FormItem";
import { CheckCircleIcon, EnvelopeIcon } from "@heroicons/react/24/solid";

import { useForm } from "react-hook-form";
import { XCircleIcon } from "@heroicons/react/20/solid";

import { CHECK_EMAIL_EXISTS, FORGOT_PASSWORD } from "./Mutations";

export interface PageLoginProps {
  className?: string;
}

const ForgotPasswordPage: FC<PageLoginProps> = ({ className = "" }) => {
  const [enableError, setEnableError] = useState(false);

  const [emailValue, setEmailValue] = useState("");
  const [debouncedInputValue, setDebouncedInputValue] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [mutateForgotFunction, { data, loading, error }] =
    useMutation(FORGOT_PASSWORD);

  const renderUnused = () => {
    if (debouncedInputValue) return false;
  };

  useEffect(() => {
    renderUnused();
  });

  const [searchEmail, { data: checkEmailData }] = useLazyQuery(
    CHECK_EMAIL_EXISTS,
    {
      onCompleted(data) {
        setEnableError(true);
      },
    }
  );

  const handleInputChange = (event: any) => {
    setEmailValue(event.target.value);
  };

  useEffect(() => {
    let timeoutId: any;
    if (emailValue) {
      timeoutId = setTimeout(() => {
        setDebouncedInputValue(emailValue);
        searchEmail({
          variables: {
            email: emailValue,
          },
        });
      }, 1000);
    }
    return () => clearTimeout(timeoutId);
  }, [emailValue, searchEmail]);

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Forgot Password - Djaminn</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32 italic">
        <h2 className="my-5 flex items-center font-Montserrat text-xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Forgot Password?
        </h2>

        <p className="my-5 text-center text-gray-500 flex items-center font-Montserrat text-sm leading-[115%] md:text-sm md:leading-[115%] font-semibold justify-center">
          Type in your email in the field below and we will send you a link to
          reset your password.
        </p>

        <div className="max-w-xl mx-auto space-y-6">
          <form
            className="grid grid-cols-1 gap-5 mb-48"
            onSubmit={handleSubmit((e: any) => {
              mutateForgotFunction({
                variables: {
                  email: emailValue,
                },
              });
            })}
          >
            <FormItem
              label="Email Address"
              desc=""
              className="font-Montserrat italic"
            >
              <div className="flex">
                <span className="inline-flex items-center px-4 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                  <EnvelopeIcon className="h-5 w-5" />
                </span>
                <Input
                  {...(register("email"),
                  {
                    value: emailValue,
                    onChange: handleInputChange,
                    required: true,
                    ref(instance) {},
                  })}
                  type="email"
                  placeholder="example@example.com"
                  className="!rounded-l-none italic"
                />
              </div>

              {errors.email?.type && (
                <p className="text-red-600">
                  {`*Email* is mandatory ${errors.email?.message}`}
                </p>
              )}

              {checkEmailData?.getUserByEmail === false &&
                enableError === true && (
                  <span className="inline-flex items-center mt-2 justify-end w-full">
                    <p className="text-xs font-thin text-white mr-2 bg-red-600 p-0.5 rounded-sm">
                      Email doesn't Exist
                    </p>
                    <XCircleIcon className="h-6 w-6 text-red-600" />
                  </span>
                )}
              {checkEmailData?.getUserByEmail === true &&
                enableError === true && (
                  <span className="inline-flex items-center mt-2 justify-end w-full">
                    <p className="text-xs text-white font-thin mr-2 bg-green-600 p-0.5 rounded-sm">
                      Email Exist
                    </p>
                    <CheckCircleIcon className="h-6 w-6 text-green-600" />
                  </span>
                )}
            </FormItem>

            {error && (
              <div className="flex items-center justify-start space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 text-red-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                  />
                </svg>

                <div className="font-thin text-xs opacity-70">
                  <span className="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-800">
                    Error, while sending reseting password link!
                  </span>
                </div>
              </div>
            )}
            {data && (
              <div className="flex items-center justify-start space-x-2">
                <CheckCircleIcon className="h-6 w-6 text-green-600" />
                <div className="font-thin text-xs opacity-70">
                  <span className="bg-red-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-green-800">
                    Password resent Link has been sent to your email!
                  </span>
                </div>
              </div>
            )}

            <ButtonPrimary
              type="submit"
              disabled={!checkEmailData?.getUserByEmail}
              className="bg-pink-500 shadow-lg hover:bg-pink-400 mt-2 font-Montserrat italic"
            >
              {loading ? (
                <>
                  <svg
                    className={`motion-safe:animate-spin dark:text-white text-black  mr-3 h-5 w-5`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <div className="font-thin text-xs opacity-70">
                    <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
                      Resetting Password
                    </span>
                  </div>
                </>
              ) : (
                "Send Reset Password Link"
              )}
            </ButtonPrimary>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
