import Logo from "shared/Logo/Logo";
import { CustomLink } from "data/types";
import React from "react";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const Footer: React.FC = () => {
  const currrentYear = new Date().getFullYear();

  return (
    <div className="nc-Footer flex relative py-3 border-t border-neutral-200 dark:border-neutral-700 items-center justify-center">
      <div className="flex flex-col items-center justify-between font-Montserrat italic">
        <Logo className="h-auto w-28 " />
        <p>
          © {currrentYear}{" "}
          <span>
            <a
              className="text-sm  underline-offset-1 hover:text-lime-500"
              href="https://www.djaminn.com/"
              target={"_blank"}
              rel="noreferrer"
            >
              Djaminn.com
            </a>
          </span>
          <span>. All rights reserved.</span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
