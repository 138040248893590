import React, { FC } from "react";
import rightImg from "images/newforseclast.svg";
import NcImage from "shared/NcImage/NcImage";

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-black text-6xl">
          RECORD YOUR <br />
          SONG ON VIDEO
        </h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          Djaminn is the only music collaborative app that let you perform on
          video
        </span>
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  );
};

export default SectionSubscribe2;
