import { gql } from "@apollo/client";

export const GET_LOGGED_USERDATA = gql`
  query User($id: ID) {
    User(id: $id) {
      avatarUrl
      tags {
        name
        __typename
      }
      artistName
      name
      email
      typeOfMusician
      age
      gender
      bio
      headerImage
      followsCount
      followedByCount
      projectsCount
      notificationsCount
      clapsCount
      commentsCount
      __typename
    }
  }
`;

export const GET_USERS_TRACKS = gql`
  query userTracks($filter: ProjectWhereInput, $skip: Int, $take: Int) {
    allProjects(
      filter: $filter
      orderBy: createdAt_DESC
      skip: $skip
      take: $take
    ) {
      id
      name
      description
      headerImage
      videoExist
      mixdownVideo
      mixdownAudio
      mixdownPath
      clapsCount
      owner {
        id
        name
        artistName
        avatarUrl
      }
      comments {
        atTimeMicros
        owner {
          id
          avatarUrl
          name
          artistName
        }
        project {
          id
          name
        }
        text
        createdAt
      }
      claps {
        owner {
          id
        }
      }
      tracks {
        id
        originalOwner {
          id
          name
          avatarUrl
          __typename
        }
        recordings {
          id
        }
      }
    }
  }
`;

export const GET_USERS_FOLLOWINGS = gql`
  query allUsers($me: ID, $name: String, $take: Int) {
    allUsers(
      filter: {
        NOT: { name: "Anonymous" }
        followedBy_some: { id: $me }
        name_contains: $name
      }
      take: $take
    ) {
      avatarUrl
      bio
      headerImage
      name
      id
      __typename
    }
  }
`;

export const GET_USERS_FOLLOWERS = gql`
  query allUsers($me: ID, $name: String, $take: Int) {
    allUsers(
      filter: {
        NOT: { name: "Anonymous" }
        follows_some: { id: $me }
        name_contains: $name
      }
      take: $take
    ) {
      avatarUrl
      bio
      headerImage
      name
      id
      __typename
    }
  }
`;
