import React, { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { Helmet } from "react-helmet";
import Heading from "components/Heading/Heading";
import { SAMPLE_LIST_QUERY } from "./SampleList_query";
import { useQuery } from "@apollo/client";
import CardNFTMusic2 from "components/CardNFTMusic2";

const plans = [
  {
    name: "Startup",
    ram: "12GB",
    cpus: "6 CPUs",
    disk: "160 GB SSD disk",
  },
  {
    name: "Business",
    ram: "16GB",
    cpus: "8 CPUs",
    disk: "512 GB SSD disk",
  },
  {
    name: "Enterprise",
    ram: "32GB",
    cpus: "12 CPUs",
    disk: "1024 GB SSD disk",
  },
];

type SampleListPageProps = {};

const SampleListPage: React.FC<SampleListPageProps> = () => {
  const { loading, error, data } = useQuery(SAMPLE_LIST_QUERY, {
    variables: {
      where: {
        genre: {
          name: "Hip-hop",
        },
      },
    },
  });

  const [selected, setSelected] = useState(plans[0]);

  const [playerUrl, setPlayerUrl] = useState(
    "https://www.mfiles.co.uk/mp3-downloads/franz-schubert-standchen-serenade.mp3"
  );

  const [check, setCheck] = useState(false);

  useEffect(() => {}, [data, check]);

  if (loading) {
    return (
      <div className="container mt-40 mb-40 flex flex-col mx-auto justify-center items-center">
        <svg
          className={`motion-safe:animate-spin dark:text-white text-black -ml-1 mr-3 h-10 w-10`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <div className="font-thin text-xs mt-4">Loading Sample list</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-40 mb-40 flex flex-col mx-auto justify-center items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="text-red-700 -ml-1 mr-3 h-10 w-10"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
          />
        </svg>
        <div className="font-thin text-sm mt-4">
          Error While, Loading Sample list.
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="container w-2/4 rounded-sm">
        <CardNFTMusic2
          className="bg-gradient-to-r from-[#fef9c3] via-[#a3e635] to-[#ec4899]"
          rotatePlayButton={true}
          src={playerUrl}
        />
      </div>
      <div className="container w-full flex flex-col items-center justify-center">
        <Helmet>
          <title>Samples List - Djaminn</title>
        </Helmet>
        <Heading
          className=" dark:text-neutral-50 text-black-900 mt-12"
          fontClass="text-3xl md:text-4xl 2xl:text-5xl font-bold"
          isCenter
          desc="Select Your Track & Hit above Player Button"
        >
          Samples List
        </Heading>

        <div className="w-full px-4 py-16">
          <div className="mx-auto w-full max-w-4xl">
            <RadioGroup value={selected} onChange={setSelected}>
              <RadioGroup.Label className="sr-only">
                Server size
              </RadioGroup.Label>
              <div className="space-y-2">
                {data?.samples.map((plan: any) => (
                  <RadioGroup.Option
                    key={plan.name}
                    value={plan.mp3Url}
                    onClick={() => {
                      setPlayerUrl(plan.mp3Url);
                      setCheck(true);
                    }}
                    className={({ active, checked }) =>
                      `${""}
              ${
                checked
                  ? "bg-gradient-to-r from-[#fef9c3] via-[#a3e635] to-[#ec4899] shadow-xl text-black"
                  : "bg-white shadow-2xl"
              }
                relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                    }
                  >
                    {({ active, checked }) => (
                      <div className="flex w-full items-center justify-between">
                        <div className="items-center flex flex-row">
                          <div className="shrink-0 text-white ml-3 mr-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="#000"
                              className="w-10 h-10"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 9l10.5-3m0 6.553v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 11-.99-3.467l2.31-.66a2.25 2.25 0 001.632-2.163zm0 0V2.25L9 5.25v10.303m0 0v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 01-.99-3.467l2.31-.66A2.25 2.25 0 009 15.553z"
                              />
                            </svg>
                          </div>
                          <div className="text-sm">
                            <RadioGroup.Label
                              as="p"
                              className={`font-medium  ${
                                checked ? "dark:text-gray-900" : "text-gray-900"
                              }`}
                            >
                              {plan.name}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className={`inline ${
                                checked ? "dark:text-gray-900" : "text-gray-900"
                              }`}
                            >
                              <span>{plan.bpm}</span>
                            </RadioGroup.Description>
                          </div>
                        </div>
                        {checked ? (
                          <div className="shrink-0 text-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="#000"
                              className="w-8 h-8"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 5.25v13.5m-7.5-13.5v13.5"
                              />
                            </svg>
                          </div>
                        ) : (
                          <div className="shrink-0 text-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="#000"
                              className="w-8 h-8"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                              />
                            </svg>
                          </div>
                        )}
                      </div>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
        </div>
      </div>
    </>
  );
};

export default SampleListPage;
