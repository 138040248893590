import { FC, useEffect, useId, useRef } from "react";
import Avatar from "shared/Avatar/Avatar";
import LikeSaveBtns from "./LikeSaveBtns";
import TabDetail from "./TabDetail";
import Glide from "@glidejs/glide";
import SectionLargeSlider from "containers/PageHome/SectionLargeSlider";
import { useQuery } from "@apollo/client";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import AppStorePng from "../../images/socials/appstore.png";
import PlayStorePng from "../../images/socials/playstore.png";
import { GET_USERS_TRACKS } from "containers/AuthorPage/auther_Page_query";

import ReactGA4 from "react-ga4";

export interface UserTracksDetailsPageProps {
  className?: string;
  isPreviewMode?: boolean;
  onClickNext?: () => void;
  onClickPrev?: () => void;
}
const UserTracksDetailsPage: FC<UserTracksDetailsPageProps> = ({
  className = "",
  isPreviewMode,
  onClickNext = () => {},
  onClickPrev = () => {},
}) => {
  const params: any = useParams();

  const visIndex: any = params.indexVideo;
  const UID_FOR_QUERY: any = params.USER_ID;

  useEffect(() => {
    /* Non-interaction event */
    // // ReactGA.pageview(window.location.pathname);
    ReactGA4.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  const { loading, error, data } = useQuery(GET_USERS_TRACKS, {
    variables: {
      filter: {
        status: "PUBLISHED",
        owner: {
          id: UID_FOR_QUERY,
        },
      },
      take: 12,
    },
  });

  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }
    const OPTIONS: Glide.Options = {
      perView: 1,
      startAt: visIndex,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          gap: 28,
          perView: 1,
        },
        1024: {
          gap: 20,
          perView: 1,
        },
        768: {
          gap: 20,
          perView: 1,
        },
        500: {
          gap: 20,
          perView: 1,
        },
      },
    };
    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    // @ts-ignore
    return () => slider.destroy();
  }, [sliderRef, UNIQUE_CLASS, data, visIndex]);

  if (loading) {
    return (
      <div className="container mt-40 mb-40 flex flex-col mx-auto justify-center items-center">
        <svg
          className={`motion-safe:animate-spin dark:text-white text-black -ml-1 mr-3 h-10 w-10`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <div className="font-thin text-sm mt-4">Fetching All Djaminn</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-40 mb-40 flex flex-col mx-auto justify-center items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="animate-ping text-red-700 -ml-1 mr-3 h-10 w-10"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
          />
        </svg>
        <div className="font-thin text-sm mt-4">Error Fetching All Djaminn</div>
      </div>
    );
  }

  return (
    <div className={`nc-SectionSliderCollections mb-28 ${className}`}>
      <BgGlassmorphism />
      <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {data?.allProjects.map((item: any, index: any) => {
              return (
                <li
                  className={`glide__slide `}
                  key={`${"" + index}`}
                  id={index}
                >
                  <main className="container mt-11 flex ">
                    <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-10 md:gap-14">
                      {/* CONTENT */}
                      <div className="space-y-8 lg:space-y-10">
                        {/* HEADING */}
                        <div className="relative">
                          <SectionLargeSlider
                            nameP={item.name}
                            headerImage={item.headerImage}
                            mixdownVideo={item.mixdownVideo}
                            ownerAvatar={item.owner.avatarUrl}
                            ownerName={item.owner.artistName}
                            ownerFCMTOKEN={item.owner.fcm_token}
                            description={item.description}
                            commentsCount={item.commentsCount}
                            ClapsCount={item.clapsCount}
                            TracksCount={item.tracksCount}
                            ShareLink={item.mixdownVideo}
                            ProjectID={item.id}
                            ProjectTracksArray={item.tracks}
                          />
                        </div>
                      </div>

                      {/* SIDEBAR */}
                      <div className="pt-10 lg:pt-0 border-t-2 border-neutral-200 dark:border-neutral-700 lg:border-t-0  flex flex-col justify-center items-center">
                        <RenderSection1
                          nameP={item.name}
                          headerImage={item.headerImage}
                          mixdownVideo={item.mixdownVideo}
                          ownerAvatar={item.owner.avatarUrl}
                          ownerId={item.owner.id}
                          ownerName={item.owner.artistName}
                          ownerFCMTOKEN={item.owner.fcm_token}
                          description={item.description}
                          commentsCount={item.commentsCount}
                          ClapsCount={item.clapsCount}
                          TracksCount={item.tracksCount}
                          ShareLink={item.mixdownVideo}
                          ProjectID={item.id}
                          ProjectCommentArray={item.comments}
                          ProjectTracksArray={item.tracks}
                          ProjectComment={item.comments.text}
                          ProjectCommentTime={item.comments.atTimeMicros}
                          ProjectCommentOwner={item.comments.owner}
                          ProjectCommentProject={item.comments.project}
                        />
                      </div>
                    </div>
                  </main>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default UserTracksDetailsPage;

const RenderSection1 = (props: any) => {
  return (
    <div className="divide-y w-full divide-neutral-100 dark:divide-neutral-800">
      <Helmet>
        <title>User Track Explore the Artist - Djaminn</title>
        <meta name="description" content="App Description" />
      </Helmet>
      {/* ---------- 1 ----------  */}
      <div className="pb-9 space-y-5">
        <div className="flex justify-end items-center">
          <LikeSaveBtns
            ShareLink={`${window.location.origin}/song/${props.ProjectID}`}
          />
        </div>
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-Montserrat italic">
          {props.nameP}
        </h2>

        {/* ---------- 4 ----------  */}
        <div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-8 text-sm">
          <div className="flex items-center ">
            <Link
              to={{
                pathname: `/user/${props.ownerId}`,
                state: {
                  id: props.ownerId,
                },
              }}
            >
              <Avatar
                imgUrl={props.ownerAvatar}
                sizeClass="h-9 w-9"
                radius="rounded-full"
              />
            </Link>
            <span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
              <span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
                <Link
                  to={{
                    pathname: `/user/${props.ownerId}`,
                    state: {
                      id: props.ownerId,
                    },
                  }}
                >
                  <span>{props.ownerName}</span>
                </Link>
              </span>
              <span className="text-sm">Creator</span>
            </span>
          </div>
          <div className="hidden sm:block h-6 border-l border-neutral-200 dark:border-neutral-700"></div>
        </div>
      </div>

      {/* ---------- 7 ----------  */}
      {/* PRICE */}
      <div className="pb-9 pt-14">
        <div className="flex flex-col sm:flex-row sm:items-end sm:justify-between">
          <div className="flex-1 flex flex-col sm:flex-row items-baseline p-6 border-2 border-green-500 rounded-xl relative">
            <span className="absolute bottom-full translate-y-1 py-1 px-1.5 bg-white dark:bg-neutral-900 text-sm text-neutral-500 dark:text-neutral-400">
              Description
            </span>

            <span className="text-lg text-neutral-400 sm:ml-5">
              {props.description ? props.description : "No Description Found"}
            </span>
          </div>
        </div>
      </div>

      {/* ---------- 9 ----------  */}
      <div className="pt-9 pb-9">
        {localStorage.getItem("token") ? (
          <TabDetail
            ownerFCMTOKEN={props.ownerFCMTOKEN}
            ProjectTracksArray={props.ProjectTracksArray}
            ProjectID={props.ProjectID}
          />
        ) : (
          <>
            <div className="container flex flex-row items-center justify-center mt-4 border rounded-xl shadow dark:border-slate-800 border-slate-400 py-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#fff"
                className="w-6 h-6"
              >
                <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z" />
                <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z" />
              </svg>

              <div className="mx-6">
                <p className="text-center text-slate-600">
                  Login to See Comments
                </p>
                <Link to={"/login"}>
                  <p className="text-center text-sm hover:text-pink-600">
                    Login Here.
                  </p>
                </Link>
              </div>
            </div>
            <div className="flex flex-row w-80 justify-start items-center">
              <div className="w-min h-16 flex items-center mt-3">
                <a
                  className="h-auto w-36"
                  href="https://apps.apple.com/tt/app/djaminn-create-songs-together/id1634589883"
                >
                  <img
                    src={AppStorePng}
                    alt="App Store"
                    className="h-auto w-36 object-contain "
                  />
                </a>
              </div>
              <div className="w-min h-16 flex items-center mt-3 ml-3">
                <a
                  className="h-auto w-36"
                  href="https://play.google.com/store/apps/details?id=com.djamin"
                >
                  <img
                    src={PlayStorePng}
                    alt="Play Sotre"
                    className="h-auto w-36 object-contain "
                  />
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
