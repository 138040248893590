import { gql } from "@apollo/client";

export const REST_PASSWORD = gql`
  mutation ResetPasswordByEmail($tokenByEmail: String!, $password: String!) {
    resetPasswordByEmail(tokenByEmail: $tokenByEmail, password: $password) {
      id
      token
    }
  }
`;
