import React, { FC, useEffect, useId, useRef } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import CardNFTVideo from "./CardNFTVideo";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_LATEST_CONTESTS_ADITIONS } from "DataProvider/Queries_gql";
import ReactGA4 from "react-ga4";

/* 
 interactive Event
*/
const GA_Event = (name: string) => {
  ReactGA4.event({
    category: `Djaminn - Happening Now`,
    label: `${name}`,
    action: "Seeing Happening Now!",
    value: 3,
  });
};

export interface SectionLatestContestsProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  contestId: string;
}

const SectionLatestContests: FC<SectionLatestContestsProps> = (props) => {
  const { className = "", itemClassName = "", contestId } = props;
  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  const { loading, error, data } = useQuery(GET_LATEST_CONTESTS_ADITIONS, {
    variables: {
      take: 8,
      orderBy: "projectClapsCount_DESC",
      where: {
        contest: {
          id: contestId,
        },
      },
    },
  });

  useEffect(() => {
    ReactGA4.send({ hitType: "pageview", page: window.location.pathname });

    if (!sliderRef.current) {
      return;
    }

    const OPTIONS: Glide.Options = {
      perView: 3,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          gap: 28,
          perView: 2.5,
        },
        1024: {
          gap: 20,
          perView: 2.3,
        },
        768: {
          gap: 20,
          perView: 1.5,
        },
        500: {
          gap: 20,
          perView: 1,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    // @ts-ignore
    return () => slider.destroy();
  }, [sliderRef, UNIQUE_CLASS, data]);

  if (loading) {
    return (
      <div className="container mt-40 flex flex-col mx-auto justify-center items-center">
        <svg
          className={`motion-safe:animate-spin dark:text-white text-black-ml-1 mr-3 h-10 w-10`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <div className="font-thin text-sm mt-4">Fetching Contests</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-40 flex flex-col mx-auto justify-center items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="animate-ping text-red-700 -ml-1 mr-3 h-10 w-10"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
          />
        </svg>
        <div className="font-thin text-sm mt-4">Error Fetching Contests</div>
      </div>
    );
  }

  return (
    <div className={`nc-SectionSliderCardNftVideo ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
        <Heading desc="" hasNextPrev className="mb-5"></Heading>

        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {data?.contestProjects.map((item: any, index: any) => (
              <Link
                to={{
                  pathname: `/play-song/${item.project.id}`,
                }}
                key={`${"" + index}`}
                onClick={() => {
                  GA_Event(item.project.name);
                }}
              >
                <li
                  key={`${"" + index}`}
                  className={`glide__slide ${itemClassName}`}
                >
                  <CardNFTVideo
                    featuredImage={item.project.headerImage}
                    reelName={item.project.name}
                    src={item.mixdownVideo}
                    UserAvatar={item.project.owner.avatarUrl}
                    ownerName={item.project.owner.artistName}
                    ProjectID={item.project.id}
                    ClapsCount={item.project.clapsCount}
                    showDetails={true}
                    showIndexNumber={true}
                    IndexNumber={index + 1}
                    ownerFCMTOKEN={item.project.owner.fcm_token}
                  />
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionLatestContests;
