import { gql } from "@apollo/client";

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const CHECK_EMAIL_EXISTS = gql`
  query Query($email: String!) {
    getUserByEmail(email: $email)
  }
`;
