import React, { FC } from "react";

export interface AddTrackBtnProps {
  className?: string;
  colorClass?: string;
  isClicked?: boolean;
  colorStroke?: string;
}

const AddTrackBtn: FC<AddTrackBtnProps> = ({
  className = "",
  colorClass = "",
  isClicked = false,
  colorStroke = "#ec4899",
}) => {
  return (
    <div
      className={`flex items-center justify-center rounded-full ${colorClass} ${className}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke={colorStroke}
        className="w-10 h-10"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    </div>
  );
};

export default AddTrackBtn;
